import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";

const initialState = {
    loading: false,
    error: null,
    success: false
};

const emailSlice = createSlice({
    name: "email",
    initialState,
    reducers: {
        sendEmailToMentioned: (state) => {
            state.loading = true;
            state.error = null;
        },
        sendEmailSuccess: (state) => {
            state.loading = false;
            state.success = true;
            state.error = null;
        },
        sendEmailFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => initialState);
    }
});

export const {
    sendEmailToMentioned,
    sendEmailSuccess,
    sendEmailFailure
} = emailSlice.actions;

export default emailSlice.reducer;