import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Link,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPUploadedProof,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import { setCIDueDate, updateIssueData } from "../../reducers/audit.reducer";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DisplayUploadedProof from "./CAP/displayUploadedProof.component";

const AuditCapDetailsStatusUploadedProof = ({ data }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const uploadedProof = useSelector(selectAuditCAPUploadedProof);
  const selectedIssueId = useSelector(selectSelectedAuditIssueId);

  const handleApprove = (issueId, status) => {
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        changes: { status },
        issueId,
      })
    );
  };

  console.log("data", data);
  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    if (data?.ciDueDate) {
      setSelectedDate(new Date(data.ciDueDate).toISOString().split("T")[0]);
    }
  }, [data?.ciDueDate]);
  const handleSetStatusToCI = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    dispatch(
      setCIDueDate({
        auditId: params?.auditId,
        ciDueDate: newDate,
        issueId: selectedIssueId,
      })
    );
  };

  //   const uploadedProofWithUrl = useSelector(selectAuditCAPUploadedProofWithUrl);

  return (
    data.status !== "SITE_ADDING_PROOF" &&
    data.status !== "BRAND_APPROVED_PLAN" && (
      <Grid>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: "#475467",
              fontSize: "30px",
              fontWeight: 500,
              textDecorationLine: "underline",
              my: 4,
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            {data?.status === "SITE_ADDED_PROOF" ? "Uploaded Proof" : "Proof"}
          </Typography>
          {data?.status === "SITE_ADDED_PROOF" ? (
            <>
              <Button
                variant="contained"
                sx={{
                  ml: 2,
                  textTransform: "none",
                  backgroundColor: "#2D3282",
                  borderRadius: "8px",
                  border: "1px solid #2D3282",
                  padding: "4px 16px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                onClick={() => {
                  handleApprove(selectedIssueId, 9);
                }}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                sx={{
                  ml: 2,
                  textTransform: "none",
                  backgroundColor: "#2D3282",
                  borderRadius: "8px",
                  border: "1px solid #2D3282",
                  padding: "4px 16px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                onClick={() => {
                  handleApprove(selectedIssueId, 12);
                }}
              >
                Set Status to CI
              </Button>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#4CAF50",
                ml: 2,
              }}
            >
              <CheckCircleIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontWeight: 600 }}>
                {data?.status === "CONTINUOUS_IMPROVEMENT"
                  ? "Continuous Improvement"
                  : "Approved"}
              </Typography>
            </Box>
          )}
          {data?.status === "CONTINUOUS_IMPROVEMENT" && (
            <TextField
              type="date"
              label="CI due date"
              value={selectedDate || ""}
              onChange={handleSetStatusToCI}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ ml: 2 }}
            />
          )}
        </Box>
        {uploadedProof?.[0]?.description &&
          uploadedProof?.[0]?.description.trim() !== "" && (
            <Box
              sx={{
                padding: "12px",
                borderRadius: "12px",
                background: "#b6b6b614",
                mt: 2,
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  // backgroundColor: "#FFFFE0",
                  px: 1,
                  py: 0.5,
                  borderRadius: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Text Input:{" "}
                </Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 500, mt: 1 }}>
                  {uploadedProof?.[0]?.description}
                </Typography>
              </Typography>
            </Box>
          )}
        <DisplayUploadedProof uploadedProof={uploadedProof} />
      </Grid>
    )
  );
};

export default AuditCapDetailsStatusUploadedProof;
