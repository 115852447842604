import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
} from "@mui/material";
import TopBreadcrumbs from "../topBreadcrumbs.component";

import { debounce } from "lodash";
import RiskScatterPlot from "../RiskDetails/riskScatterPlot.component";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  selectSearchCount,
  selectSearchResultsSupplierList,
  selectSupplierCache,
  selectSupplierList,
  selectTotalCount,
} from "../../selectors/supplier.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { BorderLeft } from "@mui/icons-material";

const SupplierMetaDataGrid = () => {
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);
  const navigateToSupplierDetails = (supplierId) => {
    dispatch(getSupplierById(supplierId));
    navigate(`${location.pathname}/${supplierId}`);
  };

  const rows = suppliersList.map((supplier) => {
    return {
      id: supplier?.supplierId ?? "",
      associatedSupplierId: supplier?.metadata?.associatedSupplierId ?? "",
      supplierIdFromMetadata: supplier?.metadata?.uniqueSupplierId ?? "",
      supplierName: supplier?.name ?? "",
      supplierAlias: supplier?.metadata?.alias ?? "",
      supplierAddress: supplier?.metadata?.companyAddress ?? "",
      contactName: supplier?.metadata?.contactPerson?.name ?? "",
      contactPosition: supplier?.metadata?.contactPerson?.position ?? "",
      contactPhone: supplier?.metadata?.contactPerson?.phone ?? "",
      contactEmail: supplier?.metadata?.contactPerson?.email ?? "",
      productCategories: supplier?.metadata?.productCategories ?? "",
      productSubcategories: supplier?.metadata?.productSubcategories ?? "",
      topProductGroup: supplier?.metadata?.topProduct ?? "",
      privateLabel: supplier?.metadata?.privateLabel === "Yes" ? "Yes" : "No",
      countryFromMetadata: supplier?.metadata?.country ?? "",
      streetAddress: supplier?.metadata?.streetAddress ?? "",
      city: supplier?.metadata?.city ?? "",
      stateProvince: supplier?.metadata?.stateProvince ?? "",
      zipCode: supplier?.metadata?.zipCode ?? "",
      spend2023: supplier?.metadata?.spend ? Number(Number(supplier.metadata.spend).toFixed(2)) : "",
      formattedSpend2023: supplier?.metadata?.spend
        ? `$${Number(supplier.metadata.spend).toLocaleString("en-US", {maximumFractionDigits: 2})}`
        : "",
      spend2024: supplier?.metadata?.spend2024 ? Number(Number(supplier.metadata.spend2024).toFixed(2)) : "",
      formattedSpend2024: supplier?.metadata?.spend2024
        ? `$${Number(supplier.metadata.spend2024).toLocaleString("en-US", {maximumFractionDigits: 2})}`
        : "",
      status: supplier?.status ?? "",
      totalSites: supplier?.factoryData?.totalFactories ?? "",
      totalActiveSites: supplier?.factoryData?.totalActiveFactories ?? "",
      envTotalSqFt: supplier?.metadata?.environmental?.['2023SelfReport']?.general?.sqFt ?? "",
      envTotalEmissions: supplier?.metadata?.environmental?.['2023SelfReport']?.general?.mtCO2e ?? "",
      envEmissionsIntensity: supplier?.metadata?.environmental?.['2023SelfReport']?.general?.mtCO2ePerSqFt ?? "",
      envCarbonNeutral: supplier?.metadata?.environmental?.['2023SelfReport']?.general?.isCarbonNeutral ?? "",
      envIsVerified: supplier?.metadata?.environmental?.['2023SelfReport']?.general?.isVerified ?? "",
      envValidationOrg: supplier?.metadata?.environmental?.['2023SelfReport']?.general?.validationOrganization ?? "",
    };
  });

  const columns = [
    { field: "supplierIdFromMetadata", headerName: "Supplier ID", width: 100 },
    {
      field: "supplierName",
      headerName: "Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) =>
        params
          ? params.charAt(0).toUpperCase() + params.slice(1).toLowerCase()
          : "‑",
      renderCell: (params) =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : "-",
    },
    {
      field: "totalSites",
      headerName: "Total Sites",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalActiveSites",
      headerName: "Total Active Sites",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedSupplierId",
      headerName: "Associated Supplier ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "supplierAlias",
      headerName: "Supplier Alias",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "streetAddress",
      headerName: "Street Address",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stateProvince",
      headerName: "State/Province",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "countryFromMetadata",
      headerName: "Country (Metadata)",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactPosition",
      headerName: "Position",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactPhone",
      headerName: "Phone",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactEmail",
      headerName: "Email",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productCategories",
      headerName: "Product Categories",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productSubcategories",
      headerName: "Product Subcategories",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "topProductGroup",
      headerName: "Top Product Group",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "privateLabel",
      headerName: "Private Label",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "spend2023",
      headerName: "2023 Spend",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "formattedSpend2023",
      headerName: "2023 Spend (formatted)",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "spend2024",
      headerName: "2024 Spend",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "formattedSpend2024",
      headerName: "2024 Spend (formatted)",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "percentageOfBusiness",
      headerName: "Percentage of Business",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "envTotalSqFt",
      headerName: "(2023) Total Square Footage ",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "envTotalEmissions",
      headerName: "(2023) Total Emissions",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "envEmissionsIntensity",
      headerName: "(2023) Emissions Intensity",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "envCarbonNeutral",
      headerName: "(2023) Carbon Neutral",
      width: 130,
      align: "center", 
      headerAlign: "center",
      renderCell: (params) =>
        params.value === true ? "Yes" : params.value === false ? "No" : ""
    },
    {
      field: "envIsVerified",
      headerName: "(2023) Verified",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.value === true ? "Yes" : params.value === false ? "No" : ""
    },
    {
      field: "envValidationOrg",
      headerName: "(2023) Validation Organization",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: ".",
    //   headerName: "",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Button
    //       variant="contained"
    //       onClick={() => {
    //         // console.log("params.row.supplierId", params.row);
    //         navigateToSupplierDetails(params.row.id);
    //       }}
    //       sx={{
    //         backgroundColor: "#6172F3",
    //         color: "white",
    //         "&:hover": {
    //           backgroundColor: "#5667e2",
    //           fontWeight: "bold",
    //         },
    //         textTransform: "none",
    //         width: "79px",
    //         height: "40px",
    //         borderRadius: "8px",
    //       }}
    //       size="small"
    //     >
    //       Details
    //     </Button>
    //   ),
    // },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const totalCount = useSelector(selectTotalCount);
  const searchResults = useSelector(selectSearchResultsSupplierList);
  const searchCount = useSelector(selectSearchCount);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [count, setCount] = useState(totalCount);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const supplierCache = useSelector((state) =>
    selectSupplierCache(state, page)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm !== "") {
      setCount(searchCount);
    } else {
      setCount(totalCount);
    }
  }, [totalCount, searchCount]);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredSuppliers(searchResults);
    } else {
      setFilteredSuppliers(suppliersList);
    }
  }, []);

  useEffect(() => {
    if (supplierCache) {
      setFilteredSuppliers(supplierCache);
    } else {
      const limit = 8;
      const offset = (page - 1) * limit;
      dispatch(getListOfAllSuppliers({ page, limit, offset }));
    }
  }, [page, supplierCache, dispatch]);

  const location = useLocation();

  return (
    <Box
      sx={{
        height: "calc(100vh - 250px)",
        width: "100%",
        padding: "24px 80px",
      }}
    >
      <DataGrid
        {...dataGridFormat}
        disableDensitySelector
        checkboxSelection
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default SupplierMetaDataGrid;
