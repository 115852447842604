import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { selectAuditData } from "../../selectors/audit.selector";


const CapManagementDetailsAppbar = ({ handleDrawerClose }) => {
  const auditData = useSelector(selectAuditData);

  return (
    <AppBar
      color="inherit"
      position="static"
      sx={{
        textAlign: "center",
        fontSize: "16px",
        fontWeight: 500,
        borderBottom: "1px solid #E0E0E0",
      }}
      elevation={0}
    >
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ alignItems: "flex-start", display: "flex" }}
              onClick={handleDrawerClose}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                // paddingTop: "15px",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              CAP
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }}>
            {auditData?.metadata?.capManagedByAuditFirm && (
              <Chip
                label="Managed by Audit Firm"
                size="small"
                sx={{
                  height: '20px',
                  fontSize: '12px',
                }}
              />
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default CapManagementDetailsAppbar; 