import React from 'react';
import { Box, Typography, Grid, Card, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSupplierData } from '../../selectors/supplier.selector';
import { SupplierStyles, ComponentStyles } from '../../styles';

const SupplierEnvironmental = () => {
  const supplierData = useSelector(selectSupplierData);
  const environmentalData = supplierData?.metadata?.environmental?.['2023SelfReport'];

  if (!environmentalData) {
    return (
      <Box>
        <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: 3, mt: 4 }}>
          Self-Reported Data (2023)
        </Typography>
        <Card sx={SupplierStyles.supplyChainOverviewCard}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: '100px',
            flexDirection: 'column',
            color: '#667085'
          }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}>
              No environmental data available
            </Typography>
            <Typography sx={{ fontSize: '14px', color: '#667085' }}>
              This supplier has not reported any environmental data for 2023
            </Typography>
          </Box>
        </Card>
      </Box>
    );
  }

  const { general, siteBreakdown } = environmentalData;

  return (
    <Box>
      <Typography sx={{ fontSize: "18px", fontWeight: 700, mb: 3, mt: 4 }}>
        Self-Reported Data (2023)
      </Typography>

      {/* General Section */}
      <Typography sx={{ fontSize: "16px", fontWeight: 600, mb: 2 }}>
        Aggregate Information
      </Typography>
      <Card sx={SupplierStyles.supplyChainOverviewCard}>
        <Grid container spacing={3} sx={{ mb: "12px" }}>
          <Grid item xs={4}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Total sq ft
            </Typography>
            <Typography sx={{...ComponentStyles.fieldValue, mt: 1}}>
              {general?.sqFt && typeof general?.sqFt === 'number'
                ? general.sqFt.toLocaleString()
                : '-'} 
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Total Emissions
            </Typography>
            <Typography sx={{...ComponentStyles.fieldValue, mt: 1}}>
              {general?.mtCO2e && typeof general?.mtCO2e === 'number'
                ? general.mtCO2e.toLocaleString()
                : '-'} mtCO2e
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Emissions Intensity
            </Typography>
            <Typography sx={{...ComponentStyles.fieldValue, mt: 1}}>
              {general?.mtCO2ePerSqFt && typeof general.mtCO2ePerSqFt === 'number' 
                ? general.mtCO2ePerSqFt.toFixed(6) 
                : '-'} mtCO2e/sq ft
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Verified
            </Typography>
            <Typography sx={{...ComponentStyles.fieldValue, mt: 1}}>
              {general?.isVerified 
                ? `Yes (${general?.validationOrganization || '-'})`
                : 'No'
              }
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              HIGG
            </Typography>
            <Typography sx={{...ComponentStyles.fieldValue, mt: 1}}>
              -
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Carbon Neutral
            </Typography>
            <Typography sx={{...ComponentStyles.fieldValue, mt: 1}}>
              {general?.isCarbonNeutral === true ? 'Yes' : 'No'}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      {/* Site Breakdown Section */}
      <Typography sx={{ fontSize: "16px", fontWeight: 600, my: 2 }}>
        Details Breakdown
      </Typography>
      <Card sx={SupplierStyles.supplyChainOverviewCard}>
        <Grid container sx={{ mb: "12px" }}>
          <Grid item xs={2}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Site
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Buildings
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              sq ft
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Emissions
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={SupplierStyles.supplyChainOverviewTitle}>
              Intensity
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        {siteBreakdown?.map((site, index) => (
          <React.Fragment key={index}>
            <Grid container sx={{ mt: "12px" }}>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldValue}>
                  {site?.supplier || '-'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldValue}>
                  {site?.type || '-'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldValue}>
                  {site?.buildings || '-'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldValue}>
                  {site?.sqft && typeof site?.sqft === 'number'
                    ? site.sqft.toLocaleString()
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldValue}>
                  {site?.mtCO2e && typeof site?.mtCO2e === 'number'
                    ? site.mtCO2e.toLocaleString()
                    : '-'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={ComponentStyles.fieldValue}>
                  {site?.mtCO2ePerSqft && typeof site?.mtCO2ePerSqft === 'number'
                    ? site.mtCO2ePerSqft.toFixed(6)
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
            {index < siteBreakdown.length - 1 && <Divider sx={{ mt: "12px" }} />}
          </React.Fragment>
        ))}
      </Card>
    </Box>
  );
};

export default SupplierEnvironmental; 