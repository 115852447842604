import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  Fab,
  InputLabel,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomChipLabel from "./../customChipLabel.component";
import { getValidData, checkInternalEmail } from "../../../utils/lib";
import { updateAuditData } from "../../../reducers/audit.reducer";
// import { selectAuditIssueDetails } from "../../../selectors/audit.selector";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setSaveStatus } from "../../../reducers/misc.reducer";
import { selectEmail } from "../../../selectors/login.selector";
import standard from "../../../data/4i_audit_tool.json";
import smetaTo4iStandard from "../../..//data/4i_code_vs_smeta_code.json";

const EditAuditReport = ({ data }) => {
  const params = useParams();

  const initData = {
    capDetailsSeverity: getValidData(data?.severity),
    capDetailsTimeline: getValidData(data?.timeline),
    capDetailsRatingExplanation: getValidData(data?.ratingExplanation),
    capDetailsImmediateCAP:
      getValidData(data?.capRecommendations?.immediateCAP) || "",
    capDetailsLongTermPreventativeAction:
      getValidData(data?.capRecommendations?.longTermPreventativeAction) || "",
    capDetailsTargetDeadline: getValidData(data?.targetDeadline),
    capDetailsCompletionDate: getValidData(data?.completionDate),
    capDetailsStatus: getValidData(data?.status),
    capDetailsManufacturerComment: getValidData(data?.manufacturerComment),
    capDetailsVerificationNote: getValidData(data?.verificationNote),
    capDetailsResponsiblePerson: getValidData(data?.responsiblePerson),
    capDetailsNote: getValidData(data?.note),
    capDetailsIssueDetail: getValidData(data?.issueDetail),
    capDetailsRepeatedIssue: getValidData(data?.repeatedIssue) || "Coming Soon",
    capDetailsType: getValidData(data?.type),
    capDetailsSubType: getValidData(data?.subType),
    capDetailsLastUpdatedBy: getValidData(data?.lastUpdatedBy),
    capDetailsLastUpdatedDate: getValidData(data?.lastUpdatedDate),
    capDetailsAuditToolRequirement: getValidData(data?.clientCode), // 4i audit tool content
    auditBasis: getValidData(data?.auditBasis), // basis
    capDetailsRootCauseResponse: getValidData(data?.rootCauseResponse),
    capDetailsImmediateCorrectiveActionPlanFacilityInput: getValidData(
      data?.immediateCorrectiveActionPlanFacilityInput
    ),
    capDetailsLongTermPreventativeActionPlanFacilityInput: getValidData(
      data?.longTermPreventativeActionPlanFacilityInput
    ),
    capDetailsFindingId: getValidData(data?.clientCodeTitle),
    capDetailsRootCause: getValidData(data?.rootCause),
  };

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});
  const [somethingUpdated, setSomethingUpdated] = useState(false);

  // Add new state for snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Add handleClose function for snackbar
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [data]);

  const dispatch = useDispatch();

  // Add new state to track Finding ID changes
  const [findingIdChanged, setFindingIdChanged] = useState(false);

  // Modify handleChange to show the upcoming changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("target", event.target);
    setSomethingUpdated(true);

    // 4i Specific: Find the finding in the standard and update the form data
    if (name === "capDetailsFindingId") {
      const findingInStandard = convertedStandard.find(
        (item) => item.code === value
      );
      if (findingInStandard?.details) {
        setFindingIdChanged(true);
        // Store the upcoming changes to display in notification
        setFormData((prev) => ({
          ...prev,
          [name]: value,
          _upcomingChanges: {
            severity: findingInStandard.details["Criticality"] ?? "",
            timeline: findingInStandard.details["Remediation Timeline"] ?? "",
            category: findingInStandard.details["Category"] ?? "",
            subCategory: findingInStandard.details["Subcategory"] ?? "",
            requirement: findingInStandard.details["Code Criteria"] ?? "",
            ratingExplanation:""
          },
        }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    dispatch(setSaveStatus("updated"));
  };

  const handleDateChange = (newValue, name) => {
    setSomethingUpdated(true);
    setFormData({
      ...formData,
      [name]: dayjs(newValue).format("YYYY-MM-DD"),
    });
  };

  //----------------------
  // 4i Specific: convert the standard to a list of objects with code and details
  //----------------------

  const convertedStandard = Object.entries(standard).map(([code, details]) => ({
    code: details["Client Code Title"] || code,
    details,
  }));

  const sortedClientCodes = convertedStandard.sort((a, b) =>
    a.code.localeCompare(b.code, undefined, { numeric: true })
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      // 4i Specific only change the ID can change the other fields.
      if ("capDetailsFindingId" in changes) {
        const findingInStandard = convertedStandard.find(
          (item) => item.code === changes.capDetailsFindingId
        );
        if (findingInStandard?.details) {
          changes.capDetailsType = findingInStandard.details["Category"] ?? "";
          changes.capDetailsAuditToolRequirement =
            findingInStandard.details["Code Criteria"] ?? "";
          changes.capDetailsSeverity =
            findingInStandard.details["Criticality"] ?? "";
          changes.capDetailsSubType =
            findingInStandard.details["Subcategory"] ?? "";
          changes.capDetailsTimeline =
            findingInStandard.details["Remediation Timeline"] ?? "";
          changes.capDetailsRatingExplanation = ""
        }
      }

      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes,
          issueId: data.id,
        })
      );
      dispatch(setSaveStatus(null));
      setSomethingUpdated(false);
      setFindingIdChanged(false);

      // Show success message
      setSnackbar({
        open: true,
        message: "Changes saved successfully",
        severity: "success",
      });
    }
  };

  const handleLogFindingId = () => {
    console.log(`the current ${formData.capDetailsFindingId}`);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ p: 2, mt: 4 }}>
          {/* Header Section */}
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                }}
                gutterBottom
              >
                Finding
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: 400,
                  mt: 2,
                  mb: 3,
                }}
              >
                {data.issueSummary}
              </Typography>
            </Grid>
          </Grid>

          {/* Non-Internal User: Details Section */}

              {/* 4i Specific: First Row - Finding ID */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={3}>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="finding-id-label">Finding ID</InputLabel>
                    <Select
                      labelId="finding-id-label"
                      id="finding-id"
                      value={formData.capDetailsFindingId}
                      label="Finding ID"
                      name="capDetailsFindingId"
                      onChange={handleChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        PaperProps: {
                          style: {
                            maxHeight: 400,
                          },
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    >
                      {sortedClientCodes.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.code +
                            " - " +
                            (item.details["Code Criteria"]?.length > 20
                              ? item.details["Code Criteria"].substring(0, 40) +
                                "..."
                              : item.details["Code Criteria"])}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {findingIdChanged && (
                  <Grid item xs={9}>
                    <Paper
                      elevation={0}
                      sx={{
                        backgroundColor: "#FFF4E5",
                        p: 2,
                        borderRadius: "8px",
                        mt: 2,
                        border: "1px solid #FFB74D",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#664D03",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        Note: Change the Finding ID will automatically update
                        the other fields to match the new finding ID.
                        <ul style={{ margin: "4px 0" }}>
                          <li>
                            Category: {formData._upcomingChanges?.category}
                          </li>
                          <li>
                            Subcategory:{" "}
                            {formData._upcomingChanges?.subCategory}
                          </li>
                          <li>
                            Master Finding List:{" "}
                            {formData._upcomingChanges?.requirement}
                          </li>
                          <li>
                            Severity: {formData._upcomingChanges?.severity}
                          </li>
                          <li>
                            Timeline: {formData._upcomingChanges?.timeline}
                          </li>
                          <li>
                            AI Rating Explanation: This section will be cleared after overrding the original finding rating
                          </li>
                        </ul>
                      </Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>

              {/* Second Row - Category, Subcategory, Repeat Finding */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Category
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsType}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Subcategory
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsSubType}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Repeat Finding
                  </Typography>
                  <Chip
                    label={formData.capDetailsRepeatedIssue}
                    sx={{
                      fontSize: "14px",
                      color: "#344054",
                      fontWeight: 500,
                      textAlign: "center",
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      background: "#FFF",
                      padding: "4px 10px",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ my: 2 }}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Finding Detail
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsIssueDetail}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Audit Code or Legal Reference
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.auditBasis}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Match From Master Finding List
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsAuditToolRequirement}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Severity
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsSeverity}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    Remediation Timeline
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsTimeline}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ mb: 2 }}>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: 700,
                      mb: 1,
                    }}
                  >
                    AI Rating Explanation
                  </Typography>
                  <Typography
                    sx={{
                      color: "#45464E",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {formData.capDetailsRatingExplanation}
                  </Typography>
                </Grid>
              </Grid>


          {/* Only Internal Users Can Edit */}
          {isInternalUser && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "orange",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Only internal user can edit. Note: Change the Finding ID will
                  automatically update the other fields: category, sub-category,
                  tool requriement, Severity, and remediation timeline.
                </Typography>
                <Typography
                  sx={{
                    color: "orange",
                    fontSize: "16px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Original ID from audit is - {data?.referenceId}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Finding ID"
                  name="capDetailsFindingId"
                  variant="outlined"
                  value={formData.capDetailsFindingId}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Category"
                  name="capDetailsType"
                  variant="outlined"
                  value={formData.capDetailsType}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  label="Subcategory"
                  name="capDetailsSubType"
                  variant="outlined"
                  value={formData.capDetailsSubType}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              {/* <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Repeat Finding"
                  name="capDetailsRepeatedIssue"
                  variant="outlined"
                  value={formData.capDetailsRepeatedIssue}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid> */}
              <Grid item xs={12} sx={{ my: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  label="Finding Detail"
                  name="capDetailsIssueDetail"
                  variant="outlined"
                  value={formData.capDetailsIssueDetail}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  maxRows={4}
                  label="Match From Master Finding List"
                  name="capDetailsAuditToolRequirement"
                  variant="outlined"
                  value={formData.capDetailsAuditToolRequirement}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}

          {/* Finding Category */}
          <Grid container spacing={2} sx={{ my: 2 }}>
            {/* <Grid item xs={12} sx={{ mb: 2 }}>
              <TextField
                fullWidth
                multiline
                maxRows={2}
                label="Match From Master Finding List"
                name="capDetailsAuditToolRequirement"
                variant="outlined"
                value={formData.capDetailsAuditToolRequirement}
                onChange={handleChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid> */}

            {/* <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="demo-simple-select-label">Severity</InputLabel>
                <Select
                  label="Severity"
                  name="capDetailsSeverity"
                  value={formData.capDetailsSeverity}
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                      },
                    },
                  }}
                >
                  <MenuItem value="ZT">ZT</MenuItem>
                  <MenuItem value="Critical">Critical</MenuItem>
                  <MenuItem value="Major">Major</MenuItem>
                  <MenuItem value="Minor">Minor</MenuItem>
                  <MenuItem value="Irrelevant">Irrelevant</MenuItem>
                  <MenuItem value="Unsure">Unsure</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* Timeframe */}
            {/* <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel id="timeframe">Remediation Timeline</InputLabel>
                <Select
                  label="Timeframe"
                  name="capDetailsTimeline"
                  value={formData.capDetailsTimeline}
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                      },
                    },
                  }}
                >
                  <MenuItem value="Immediate">Immediate</MenuItem>
                  <MenuItem value="30 days">30 days</MenuItem>
                  <MenuItem value="60 days">60 days</MenuItem>
                  <MenuItem value="No timeline">No timeline</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* Rating Explanation */}
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={7}
                label="Rating Explanation"
                name="capDetailsRatingExplanation"
                variant="outlined"
                value={formData.capDetailsRatingExplanation}
                onChange={handleChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    maxHeight: "200px",
                    overflowY: "auto"
                  },
                }}
              />
            </Grid> */}

            {/* Internal Note */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Internal Note"
                name="capDetailsNote"
                variant="outlined"
                multiline
                maxRows={4}
                value={formData.capDetailsNote} // Use formData here
                onChange={handleChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            {/* <TextField
              fullWidth
              label="Root Cause Question"
              name="capDetailsRootCause"
              variant="outlined"
              value={formData.capDetailsRootCause}
              onChange={handleChange}
              multiline
              maxRows={6}
              margin="normal"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                },
              }}
            /> */}
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={4}
                label="Rating Explanation"
                name="capDetailsRatingExplanation"
                variant="outlined"
                value={formData.capDetailsRatingExplanation}
                onChange={handleChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    maxHeight: "200px",
                    overflowY: "auto"
                  },
                }}
              />
            </Grid> */}
          </Grid>

          <Grid container spacing={2}>
            {/* CAP Recommendation Section */}
            <Grid item xs={12} sx={{ pr: 4 }}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4,
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                CAP Recommendations
              </Typography>

              <TextField
                fullWidth
                label="Root Cause Question"
                name="capDetailsRootCause"
                variant="outlined"
                value={formData.capDetailsRootCause}
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="Immediate Corrective Action Plan"
                name="capDetailsImmediateCAP"
                variant="outlined"
                value={formData.capDetailsImmediateCAP} // Use formData here
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                label="Long Term Preventative Action"
                name="capDetailsLongTermPreventativeAction"
                variant="outlined"
                value={formData.capDetailsLongTermPreventativeAction} // Use formData here
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4,
                }}
              >
                Site Input
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ my: 0 }}>
                  <TextField
                    fullWidth
                    label="Accountable Person"
                    name="capDetailsResponsiblePerson"
                    variant="outlined"
                    value={formData.capDetailsResponsiblePerson}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={6} sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Target Completion Date"
                      value={
                        dayjs(formData.capDetailsTargetDeadline).isValid()
                          ? dayjs(formData.capDetailsTargetDeadline)
                          : null
                      }
                      onChange={(newValue) =>
                        handleDateChange(newValue, "capDetailsTargetDeadline")
                      }
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Root Cause Response"
                name="capDetailsRootCauseResponse"
                variant="outlined"
                value={formData.capDetailsRootCauseResponse}
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="Immediate Corrective Action"
                name="capDetailsImmediateCorrectiveActionPlanFacilityInput"
                variant="outlined"
                value={
                  formData.capDetailsImmediateCorrectiveActionPlanFacilityInput
                }
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="Long Term Preventative Action"
                name="capDetailsLongTermPreventativeActionPlanFacilityInput"
                variant="outlined"
                value={
                  formData.capDetailsLongTermPreventativeActionPlanFacilityInput
                }
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 3,
                }}
              >
                CAP Update
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      label="Status"
                      name="capDetailsStatus"
                      value={formData.capDetailsStatus}
                      onChange={handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                      <MenuItem value="CI">CI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Actual Completion Date"
                      value={
                        dayjs(formData.capDetailsCompletionDate).isValid()
                          ? dayjs(formData.capDetailsCompletionDate)
                          : null
                      }
                      onChange={(newValue) =>
                        handleDateChange(newValue, "capDetailsCompletionDate")
                      }
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Verification Note"
                name="capDetailsVerificationNote"
                variant="outlined"
                value={formData.capDetailsVerificationNote} // Use formData here
                onChange={handleChange}
                margin="normal"
                sx={{
                  mb: 10,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
              />
            </Grid> */}
          </Grid>
          {/* TODO: The "last updated by" should show the team member that changed the content*/}
          {/* <Typography
            sx={{
              color: "#000",
              textAlign: "right",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Last Updated by {formData.capDetailsLastUpdatedBy} - {formData.capDetailsLastUpdatedDate}
          </Typography> */}
        </Box>

        {/* Add the fixed button inside the form */}
        <Box
          sx={{
            position: "fixed",
            top: 130,
            right: 40,
            zIndex: 1000,
          }}
        >
          <Fab
            variant="extended"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!somethingUpdated}
            sx={{
              backgroundColor: "#585aeb",
              borderRadius: "8px",
              textTransform: "none",
              "&.Mui-disabled": {
                opacity: 1,
                backgroundColor: "#D3D3D3",
              },
            }}
          >
            Save Changes
          </Fab>
        </Box>
      </form>

      {/* Snackbar should remain outside the form */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditAuditReport;
