import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { debounce } from "lodash";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
} from "../../selectors/factory.selector";
import { selectLoadingState } from "../../selectors/misc.selector";

const FacilityMetaDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  const rows = factoriesList.map((factory) => {
    return {
      id: factory?.factoryId ?? "", // Unique ID for the grid's row key
      supplierId: factory?.supplierId ?? "",
      factoryIdFromMetadata: factory?.factoryIdFromMetadata ?? "",
      factoryName: factory?.name ?? "",
      factoryAlias: factory?.metadata?.alias ?? "",
      associatedSupplier: factory?.supplierName ?? "",
      tier: factory?.tier ?? "",
      factoryAddress: factory?.address ?? "",
      associatedAddresses: factory?.metadata?.associatedAddress ?? "",
      contactName: factory?.contactPerson?.name ?? "",
      contactPosition: factory?.contactPerson?.position ?? "",
      contactPhone: factory?.contactPerson?.phone ?? "",
      contactEmail: factory?.contactPerson?.email ?? "",
      productCategories: factory?.metadata?.productCategories ?? "",
      privateLabel: factory?.metadata?.privateLabel === "Yes" ? "Yes" : "No",
      spend: factory?.metadata?.spend ?? "",
      formattedSpend: factory?.metadata?.spend
        ? `$${Number(factory.metadata.spend).toLocaleString("en-US")}`
        : "",
      lastAuditDate: factory?.lastAuditDateTimestamp
        ? moment(factory.lastAuditDateTimestamp).format("MMMM D, YYYY")
        : "",
      confirmedNextAuditDate: factory?.metadata?.confirmedNextAuditDate
        ? moment(factory.metadata.confirmedNextAuditDate).format("MMMM D, YYYY")
        : "",
      auditScore: factory?.auditScore ?? "",
      openFindings: factory?.issueDetails?.Open ?? "",
      pastDueFindings: factory?.issueDetails?.PastDue ?? "",
      criticalFindings: factory?.issueDetails?.Critical ?? "",
      majorFindings: factory?.issueDetails?.Major ?? "",
      minorFindings: factory?.issueDetails?.Minor ?? "",
      ztFindings: factory?.issueDetails?.ZT ?? "",
      closedFindings: factory?.issueDetails?.Closed ?? "",
      domesticMigrantWorkers:
        parseInt(
          factory?.factoryInfofromLatestAudit?.domestic_migrant_workers
        ) || 0,
      foreignMigrantWorkers:
        parseInt(
          factory?.factoryInfofromLatestAudit?.foreign_migrant_workers
        ) || 0,
      homeWorkers:
        parseInt(factory?.factoryInfofromLatestAudit?.home_workers) || 0,
      productionSeasonalityLow:
        factory?.factoryInfofromLatestAudit?.production_seasonality_low ?? "",
      productionSeasonalityPeak:
        factory?.factoryInfofromLatestAudit?.production_seasonality_peak ?? "",
      originalSiteSize: factory?.factoryInfofromLatestAudit?.site_size ?? "",
      standardizedSiteSize:
        parseInt(factory?.factoryInfofromLatestAudit?.standardized_site_size) ||
        "",
      workerCountAgency:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_count_agency) || 0,
      workerCountPermanent:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_count_permanent) ||
        0,
      workerCountTemporary:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_count_temporary) ||
        0,
      workerCountTotal:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_count_total) || 0,
      workerGenderFemale:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_gender_female) ||
        0,
      workerGenderMale:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_gender_male) || 0,
      workerGenderOther:
        parseInt(factory?.factoryInfofromLatestAudit?.worker_gender_other) || 0,
      youngWorkers:
        parseInt(factory?.factoryInfofromLatestAudit?.young_workers) || 0,
      status: factory?.status ?? "",
      country: factory?.metadata?.country ?? "",
      zipCode: factory?.metadata?.zipCode ?? "",
      stateProvince: factory?.metadata?.stateProvince ?? "",
      city: factory?.metadata?.city ?? "",
      streetAddress: factory?.metadata?.streetAddress ?? "",
    };
  });

  const columns = [
    {
      field: "factoryIdFromMetadata",
      headerName: "Site ID",
      width: 100,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) => (params ? `${params}\u200B` : "‑"),
      renderCell: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "factoryName",
      headerName: "Site Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: ".",
      headerName: "Click",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            // console.log("params.row.supplierId", params.row);
            navigateToFactoryDetails(params.row.supplierId, params.row.id);
          }}
          sx={{
            backgroundColor: "#6172F3",
            color: "white",
            "&:hover": {
              backgroundColor: "#5667e2",
              fontWeight: "bold",
            },
            textTransform: "none",
            minWidth: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
    {
      field: "factoryAlias",
      headerName: "Site Aliases",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedSupplier",
      headerName: "Associated Supplier",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      align: "center",
      headerAlign: "center",
      valueFormatter: (params) =>
        params
          ? params.charAt(0).toUpperCase() + params.slice(1).toLowerCase()
          : "‑",
      renderCell: (params) =>
        params.value
          ? params.value.charAt(0).toUpperCase() +
            params.value.slice(1).toLowerCase()
          : "-",
    },
    {
      field: "tier",
      headerName: "Tier",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "streetAddress",
      headerName: "Street Address",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "city",
      headerName: "City",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "stateProvince",
      headerName: "State/Province",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "country",
      headerName: "Country",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedAddresses",
      headerName: "Associated Addresses",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactName",
      headerName: "Contact Person",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactPosition",
      headerName: "Position",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactPhone",
      headerName: "Site Phone",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactEmail",
      headerName: "Email",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productCategories",
      headerName: "Product Categories",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "privateLabel",
      headerName: "Private Label Factory?",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "spend",
      headerName: "2023 Spend",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "formattedSpend",
      headerName: "2023 Spend (formatted)",
      width: 150,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "confirmedNextAuditDate",
      headerName: "Confirmed Next Audit Date",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastAuditDate",
      headerName: "Last Audit Date",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "auditScore",
      headerName: "Audit Score",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "openFindings",
      headerName: "Open Findings",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pastDueFindings",
      headerName: "Past Due Findings",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "criticalFindings",
      headerName: "Critical Findings",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "majorFindings",
      headerName: "Major Findings",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "minorFindings",
      headerName: "Minor Findings",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ztFindings",
      headerName: "ZT Findings",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "closedFindings",
      headerName: "Closed Findings",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "standardizedSiteSize",
      headerName: "Site Size (ft²)",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "originalSiteSize",
      headerName: "Original Site Size",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "domesticMigrantWorkers",
      headerName: "Domestic Migrant Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "foreignMigrantWorkers",
      headerName: "Foreign Migrant Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "homeWorkers",
      headerName: "Home Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productionSeasonalityLow",
      headerName: "Production Seasonality (Low)",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productionSeasonalityPeak",
      headerName: "Production Seasonality (Peak)",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountAgency",
      headerName: "Agency Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountPermanent",
      headerName: "Permanent Workers",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountTemporary",
      headerName: "Temporary Workers",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountTotal",
      headerName: "Total Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderFemale",
      headerName: "Female Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderMale",
      headerName: "Male Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderOther",
      headerName: "Other Gender Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "youngWorkers",
      headerName: "Young Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  // commenting this code. This was for the old table.
  // useEffect(() => {
  //   if (factoryCache) {
  //     setFilteredFactories(factoryCache);
  //   } else {
  //     const limit = 8;
  //     const offset = (page - 1) * limit;

  //     dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
  //   }
  // }, [page, factoryCache]);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      dispatch(getSearchResultOfFactories({ search: term }));
    }, 100), // Delay in milliseconds
    []
  );

  const location = useLocation();

  return (
    <Box
      sx={{
        height: "calc(100vh - 250px)",
        width: "100%",
        padding: "24px 80px",
      }}
    >
      <DataGrid
        {...dataGridFormat}
        checkboxSelection
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            csvOptions: {
              utf8WithBom: true,
            },
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default FacilityMetaDataGrid;
