import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Divider, Card, Tooltip, Chip } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierData,
  selectSupplierNameFromId,
} from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import LeftSideNavbar from "../components/leftSideNavbar.component";
import {
  selectFacilityPageTab,
  selectFactoryData,
  selectFactoryNameFromId,
  selectFactoryTierFromId,
} from "../selectors/factory.selector";
import FacilityInformation from "../components/FacilityDetails/facilityInformation.component";
import FacilityInternalInformation from "../components/FacilityDetails/facilityInternalInformation.component";
import FacilityTabs from "../components/FacilityDetails/facilityTabs.component";
import FacilityComplianceStatus from "../components/FacilityDetails/facilityComplianceStatus.component";
import FacilityAuditList from "../components/FacilityDetails/facilityAuditList.component";
import FacilityQuestionnaires from "../components/FacilityDetails/facilityQuestionnaires.component";
import FacilityInformationDrawer from "../components/FacilityDetails/facilityInformationDrawer.component";
import { getFactoryById } from "../reducers/factory.reducer";
import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import AdditionalFactoryInformation from "../components/FacilityDetails/facilityAdditionalInformation.component";
import { PageStyles } from "../styles";
import {
  selectAuditList,
  selectAuditListForFactory,
  selectMostRecentAuditIdForFactory,
} from "../selectors/audit.selector";
import { getListOfAllAudits, getAuditById } from "../reducers/audit.reducer";
import { getSupplierById } from "../reducers/supplier.reducer";
import CommentSection from "../components/commentSection.component";
import FacilityEnvironmental from "../components/FacilityDetails/facilityEnvironmental.component";

const FacilityDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const selectedFactoryData = useSelector(selectFactoryData);
  const [isLoading, setIsLoading] = useState(false);
  const supplierData = useSelector(selectSupplierData);

  useEffect(() => {
    const fetchFactoryData = async () => {
      if (!selectedFactoryData || selectedFactoryData.id !== params.factoryId) {
        setIsLoading(true);
        try {
          await dispatch(
            getFactoryById({
              supplierId: params.supplierId,
              factoryId: params.factoryId,
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchFactoryData();
  }, [params.factoryId, params.supplierId, dispatch]);

  useEffect(() => {
    if (!supplierData || supplierData?.id !== params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    }
  }, [params.supplierId, dispatch]);

  const selectedTab = useSelector(selectFacilityPageTab);

  useEffect(() => {
    dispatch(
      getListOfAllAudits({
        limit: 99,
        offset: 0,
        supplierId: params.supplierId,
      })
    );
  }, []);

  const selectAuditId = useSelector((state) =>
    selectMostRecentAuditIdForFactory(state, params.factoryId)
  );

  // useEffect(() => {
  //   dispatch(
  //     getAuditById({
  //       supplierId: params.supplierId,
  //       factoryId: params.factoryId,
  //       auditId: selectAuditId,
  //     })
  //   );
  // }, [dispatch, selectAuditId]);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={{
          ...PageStyles.firstSectionWithBreadcrumbsAndName,
          position: "sticky",
          top: 0,
          zIndex: 1000,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
        }}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {selectedFactoryData?.name} 
                {selectedFactoryData?.metadata?.status === "inactive" && (
                  <Box
                    sx={{
                      ...PageStyles.tierAndPrivateLabel,
                      bgcolor: "#EAAA08",
                    }}
                    size="small"
                    variant="outlined"

                  >
                    Inactive Site
                  </Box>
                )}
                {selectedFactoryData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )}
                {selectedFactoryData?.metadata?.contractor === true && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Contractor
                  </Box>
                )}
                {selectedFactoryData?.metadata?.smallFactory === true && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Small Site
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <EditToggleSwitch />
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <FacilityInformation />
          </Grid>
          {/* Internal Information */}
          {/* <Grid item xs={12}>
            <FacilityInternalInformation />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <ConfidentialBusinessInformation
              title="Confidential Business Information"
              confidential={true}
              type={"factory"}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Card
              sx={{
                p: 1,
                pt: 3,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                border: "1px solid rgba(234, 236, 240, 1)",
                borderRadius: "12px",
                height: '100%', // Ensure the card takes up the full height
                margin: 'auto',
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2, ml: 2, mr: 2 }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#344054",
                      display: "inline-block",
                    }}
                  >
                    Internal Notes
                  </Typography>
                  <Tooltip title="The internal note is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
                  <Chip
                    label="Beta"
                    size="small"
                    sx={{
                      backgroundColor: "#4C5ED9",
                      color: "white",
                      fontWeight: 500,
                      fontSize: "12px",
                      height: "24px",
                      ml:1
                    }}
                  />
                </Tooltip>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mt: 0, mr: 2 }}
                >
                  <Grid item xs={11.5}>
                    <CommentSection source="factory"></CommentSection>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <AdditionalFactoryInformation />
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <FacilityTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <FacilityComplianceStatus />
                  ) : selectedTab === "audits" ? (
                    <FacilityAuditList />
                  ) : selectedTab === "environmental" ? (
                    <FacilityEnvironmental />
                  ) : (
                    <FacilityQuestionnaires factoryId={params.factoryId} />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FacilityDetails;
