import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Container,
  Divider,
  Button,
  Modal,
  Tooltip,
} from "@mui/material";
import {
  Description,
  Image,
  PictureAsPdf,
  Folder,
  MoreVert,
  Article,
  TableChart,
} from "@mui/icons-material";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import { FacilityStyles, PageStyles } from "../styles";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import { getResources } from "../reducers/audit.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrganisationId,
  selectOrganisationResources,
  selectSupplierId,
} from "../selectors/login.selector";
import { selectResources } from "../selectors/audit.selector";
import { selectSupplierResources } from "../selectors/supplier.selector";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AuditDocumentDrop from "../components/AuditDetails/auditDocumentDrop.component";
import { useParams } from "react-router-dom";

const ResourcesPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const files = [
    { id: 1, name: "Document.pdf", type: "pdf" },
    { id: 2, name: "Image.jpg", type: "image" },
    { id: 3, name: "Project Files", type: "folder" },
    { id: 4, name: "Report.doc", type: "doc" },
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFileIcon = (type) => {
    switch (type) {
      case "application/pdf":
        return <PictureAsPdf sx={{ fontSize: 50, color: "#e74c3c" }} />;
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <Article sx={{ fontSize: 50, color: "#2b579a" }} />;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return <TableChart sx={{ fontSize: 50, color: "#217346" }} />;
      case "image/png":
      case "image/jpeg":
      case "image/webp":
        return <Image sx={{ fontSize: 50, color: "#3498db" }} />;
      case "export":
        return <Folder sx={{ fontSize: 50, color: "#f1c40f" }} />;
      default:
        return <Description sx={{ fontSize: 50, color: "#2ecc71" }} />;
    }
  };

  const resourcesGroupedByType = useSelector(selectOrganisationResources);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                Resources
              </Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#344054",
                  borderRadius: "8px",
                  padding: "10px 14px",
                  border: "1px solid var(--Gray-3000, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "none",
                }}
                onClick={handleOpen}
                startIcon={<UploadFileIcon />}
              >
                Upload Proof
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Container sx={{ py: 4, maxWidth: "100% !important" }}>
          {Object.entries(resourcesGroupedByType).map(
            ([type, typeResources]) => (
              <Box key={type} sx={{ mb: 4 }}>
                <Card sx={{ ...FacilityStyles.containerCard, pb: 4, pt: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      textTransform: "capitalize",
                      my: 1,
                    }}
                  >
                    {type}
                  </Typography>
                  <Grid container spacing={4}>
                    {typeResources.map((resource) => (
                      <Grid item xs={2} key={resource.key}>
                        <Card
                          sx={{
                            position: "relative",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            px: 1,

                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                            "&:hover": {
                              backgroundColor: "#f9f9f9",
                              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                            },
                            borderRadius: "10px",
                            transition: "all 0.2s ease-in-out",
                          }}
                          elevation={0}
                        >
                          <CardContent sx={{ textAlign: "center", pb: 2 }}>
                            <Box
                              onClick={() =>
                                window.open(
                                  resource.fileUrl,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textDecoration: "none",
                                color: "inherit",
                                width: "100%",
                              }}
                            >
                              {getFileIcon(resource.type || resource.fileType)}
                              <Tooltip
                                title={resource.name || resource.fileName}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mt: 1,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#333",
                                    width: "100%",
                                    overflow: "ellipsis",
                                    textAlign: "center",
                                    lineHeight: "1.2em",
                                    height: "2.4em",
                                  }}
                                >
                                  {(resource.name || resource.fileName)
                                    .match(/.{1,26}/g)
                                    ?.join("\n") ||
                                    resource.name ||
                                    resource.fileName}
                                </Typography>
                              </Tooltip>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Box>
            )
          )}
        </Container>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            p: 4,
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
            border: "1px solid rgba(234, 236, 240, 1)",
            borderRadius: "12px",
            py: 2,
          }}
        >
          <AuditDocumentDrop
            organisationId={params.organisationId}
            supplierId={params.supplierId}
            factoryId={params.factoryId}
            onClose={handleClose}
            mode="upload"
            uploadToOrganisation={true}
          />
        </Card>
      </Modal>
    </Box>
  );
};

export default ResourcesPage;
