import React from "react";
import { Typography, Grid } from "@mui/material";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles } from "../../styles";
import moment from "moment";

const FacilityAuditScheduling = ({ selectedFactory }) => {
  const formatDate = (dateStr) => {
    if (dateStr === "N/A") return "N/A";
    return moment(dateStr).format("MM/DD/YY");
  };


  const isInactive = selectedFactory?.metadata?.status === "inactive";

  const auditDueData = {
    nextAuditDueDate: formatDate(
      selectedFactory?.auditCadence?.nextAuditDate || "N/A"
    ),
    isPastDue: moment(selectedFactory?.auditCadence?.nextAuditDate).isBefore(moment(), 'day')
  };


  const nextConfirmedAuditDate = formatDate(
    selectedFactory?.metadata?.confirmedNextAuditDate || "N/A"
  );

  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Audit Scheduling
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TwoLineCard
            title="Next Audit Due Date"
            nextAuditDate={
              isInactive ? "Inactive" : auditDueData?.nextAuditDueDate || "N/A"
            }
            textColor={
              !isInactive && auditDueData?.isPastDue ? "#B42318" : "inherit"
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TwoLineCard
            title="Upcoming Scheduled Audit Date"
            nextAuditDate={nextConfirmedAuditDate}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FacilityAuditScheduling;
