import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectDueCaps } from "../../selectors/dueAction.selector";
import { setAuditData } from "../../reducers/audit.reducer";

const DueCapsDataGrid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAuditData(null));
  }, []);
  // FUNCITON TO CONVERT DAYS to YEARS MONTHS AND DAYs
  //   const convertDaysToYearsMonthsDays = (totalDays) => {
  //     const years = Math.floor(totalDays / 365);
  //     const remainingDaysAfterYears = totalDays % 365;
  //     const months = Math.floor(remainingDaysAfterYears / 30);
  //     const days = remainingDaysAfterYears % 30;

  //     const parts = [];
  //     if (years > 0) parts.push(`${years} ${years === 1 ? 'year' : 'years'}`);
  //     if (months > 0) parts.push(`${months} ${months === 1 ? 'month' : 'months'}`);
  //     if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);

  //     return parts.join(', ');
  //   };
  const selectedDueCaps = useSelector(selectDueCaps);

  console.log("selectedDueCaps", selectedDueCaps);
  const rows = selectedDueCaps?.map((audit) => ({
    id: audit.auditId,
    factoryName: audit.factoryName,
    auditDate: audit.auditDate,
    auditScore: audit.auditScore,
    totalFindings: audit.issueDetails?.total,
    factoryId: audit.factoryId,
    supplierId: audit.supplierId,
    daysSinceAudit: audit.daysSinceAudit,
    dueStatus:
      audit.daysSinceAudit <= 0
        ? `Past ${audit.daysSinceAudit?.toString().replace("-", "")} Days`
        : `in ${audit?.daysSinceAudit?.toString().replace("-", "")} Days`,
    severityCategories: audit.severityCategories,
    issueDetails: audit.issueDetails,
    openIssues: audit.groupedDataForDueAction?.open,
    pastDueIssues: audit.groupedDataForDueAction?.pastDue,
  }));
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  // Add this filter handling function
  const handleFilterToggle = (filterType) => {
    const newFilters = selectedFilters.includes(filterType)
      ? selectedFilters.filter((f) => f !== filterType)
      : [...selectedFilters, filterType];

    setSelectedFilters(newFilters);
    
    // Apply filters
    const filtered = rows?.filter((row) => {
      if (newFilters.length === 0) return true;
      
      // Check if row matches any of the selected filters
      return newFilters.every((filter) => {
        // Handle days-based filters
        if (['pastDue', 'due1to20', 'due20to60', 'due60plus'].includes(filter)) {
          const daysSinceAudit = row?.daysSinceAudit;
          switch (filter) {
            case "pastDue":
              return daysSinceAudit <= 0;
            case "due1to20":
              return daysSinceAudit > 0 && daysSinceAudit < 20;
            case "due20to60":
              return daysSinceAudit > 20 && daysSinceAudit <= 60;
            case "due60plus":
              return daysSinceAudit > 60;
            default:
              return true;
          }
        }
        
        // Handle ZT & Critical filter
        if (filter === 'ztCritical') {
          const { groupedDataForDueAction } = row;
          const openZT = groupedDataForDueAction?.open?.ZT || 0;
          const openCritical = groupedDataForDueAction?.open?.Critical || 0;
          const pastDueZT = groupedDataForDueAction?.pastDue?.ZT || 0;
          const pastDueCritical = groupedDataForDueAction?.pastDue?.Critical || 0;

          return (
            openZT > 0 ||
            openCritical > 0 ||
            pastDueZT > 0 ||
            pastDueCritical > 0
          );
        }

        return true;
      });
    });

    setFilteredRows(filtered);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [selectedDueCaps]);

  const columns = [
    {
      field: "factoryName",
      headerName: "Site",
      width: 150,
      headerAlign: "left",
      filterable: true,
      renderCell: (params) => (
        <Typography
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px 0px 8px 8px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "auditDate",
      headerName: "Cap Start Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "dueStatus",
      headerName: "Due Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.dueStatus}
          sx={{
            borderRadius: "8px",
            backgroundColor:
            params.row.daysSinceAudit <= 0 ? "#DC6803" : "#EAECF0",
            color: params.row.daysSinceAudit <= 0 ? "#FFF" : "#172B4D",
            fontWeight: "500",
          }}
        />
      ),
    },
    {
      field: "auditScore",
      headerName: "Score",
      type: "number",
      width: 70,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalFindings",
      headerName: "Total Findings",
      type: "number",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "capStatus",
      headerName: "CAP Status",
      width: 400,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Table size="small" sx={{ width: "100%" }}>
          <TableBody>
            {/* Header row for severity categories */}
            <TableRow>
              <TableCell sx={{ borderBottom: "none", width: "80px" }} />
              {Object.keys(params.row.severityCategories || {}).map(
                (severityCategory, index) => (
                  <TableCell
                    key={index}
                    sx={{ p: 0, m: 0, borderBottom: "none", width: "80px" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "#8B8D97",
                        textAlign: "center",
                      }}
                    >
                      {severityCategory}
                    </Typography>
                  </TableCell>
                )
              )}
            </TableRow>

            {/* "Past Due" row */}
            <TableRow sx={{ borderBottom: "none" }}>
              <TableCell
                sx={{ p: 0, m: 0, borderBottom: "none", width: "80px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#8B8D97",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  Past Due
                </Typography>
              </TableCell>
              {Object.entries(params.row.severityCategories || {}).map(
                ([severityCategory, color], idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      p: 0,
                      m: 0,
                      borderBottom: "none",
                      width: "80px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor:
                          params.row.pastDueIssues?.[severityCategory] &&
                          params.row.pastDueIssues[severityCategory] !== 0
                            ? color
                            : "#FFFFFF",
                        color:
                          params.row.pastDueIssues?.[severityCategory] &&
                          params.row.pastDueIssues[severityCategory] !== 0
                            ? "#FFFFFF"
                            : "#E0E0E0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 500,
                        fontSize: 14,
                        border:
                          params.row.pastDueIssues?.[severityCategory] &&
                          params.row.pastDueIssues[severityCategory] !== 0
                            ? `1px solid ${color}`
                            : "1px solid #E0E0E0",
                      }}
                    >
                      {params.row.pastDueIssues?.[severityCategory] ?? "0"}
                    </Box>
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ maxHeight: "2px", padding: "2px", borderBottom: "none" }}
              ></TableCell>
            </TableRow>
            {/* "Open CAP" row with 8px vertical margin on top */}
            <TableRow sx={{ borderBottom: "none" }}>
              <TableCell
                sx={{
                  p: 0,
                  m: 0,
                  borderBottom: "none",
                  width: "80px",
                  marginTop: "8px", // Add margin-top to create spacing between rows
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: "#8B8D97",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  Open
                </Typography>
              </TableCell>
              {Object.entries(params.row.severityCategories || {}).map(
                ([severityCategory, color], idx) => (
                  <TableCell
                    key={idx}
                    sx={{
                      p: 0,
                      m: 0,
                      borderBottom: "none",
                      width: "80px",
                      marginTop: "8px", // Add margin-top to create spacing
                    }}
                  >
                    <Box
                      sx={{
                        height: "24px",
                        backgroundColor:
                          params.row.openIssues?.[severityCategory] &&
                          params.row.openIssues[severityCategory] !== 0
                            ? color
                            : "#FFFFFF",
                        color:
                          params.row.openIssues?.[severityCategory] &&
                          params.row.openIssues[severityCategory] !== 0
                            ? "#FFFFFF"
                            : "#E0E0E0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 500,
                        fontSize: 14,
                        border:
                          params.row.openIssues?.[severityCategory] &&
                          params.row.openIssues[severityCategory] !== 0
                            ? `1px solid ${color}`
                            : "1px solid #E0E0E0",
                      }}
                    >
                      {params.row.openIssues?.[severityCategory] ?? "0"}
                    </Box>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableBody>
        </Table>
      ),
    },
    {
      field: "details",
      headerName: "",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            window.history.pushState(null, "", "/dueAction");

            navigate(
              `/suppliers/${params.row.supplierId}/factory/${params.row.factoryId}/audit/${params.row.id}`,
              { replace: true }
            );
          }}
          sx={{
            backgroundColor: "#4B68D4",
            color: "white",
            "&:hover": {
              backgroundColor: "#3A54B6",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            ml: 4,

            borderRadius: "8px",
          }}
          size="small"
        >
          To Audit
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          Open CAP
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 60+ Days
              </Button>
              {/* <Button
                variant={selectedFilters.includes("ztCritical") ? "contained" : "outlined"}
                onClick={() => handleFilterToggle("ztCritical")}
                sx={{
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                  ml: 1, // adds left margin for spacing
                }}
              >
                ZT & Critical
              </Button> */}
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGrid
                columns={columns}
                rows={filteredRows || []}
                disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 90}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container
                    overflow: "hidden",
                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",
                            "& .MuiDataGrid-cell": {
                              // Target each cell
                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueCapsDataGrid;
