import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateFactoryData } from "../../../reducers/factory.reducer";
import { selectSupplierNameFromId } from "../../../selectors/supplier.selector";
import { ComponentStyles } from "../../../styles";
// import { updateSupplier } from '../../actions/supplierActions'; // Ensure you have this action
// import CommentSection from "../../commentSection.component";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { countries } from "../../../utils/countryData";

const FactoryInformationEditModal = ({
  open = false,
  onClose,
  internalData,
}) => {
  const initData = {
    factoryId: getValidData(internalData?.metadata?.factoryId),
    alias: getValidData(internalData?.metadata?.alias),
    parentCompany: getValidData(internalData?.metadata?.supplierName),
    supplierId: getValidData(internalData?.metadata?.supplierId),
    streetAddress: getValidData(internalData?.metadata?.streetAddress),
    city: getValidData(internalData?.metadata?.city),
    stateProvince: getValidData(internalData?.metadata?.stateProvince),
    zipCode: getValidData(internalData?.metadata?.zipCode),
    country: getValidData(internalData?.metadata?.country),
    contactPersonName: getValidData(
      internalData?.metadata?.contactPerson?.name
    ),
    contactPersonPosition: getValidData(
      internalData?.metadata?.contactPerson?.position
    ),
    contactPersonPhone: getValidData(
      internalData?.metadata?.contactPerson?.phone
    ),
    contactPersonEmail: getValidData(
      internalData?.metadata?.contactPerson?.email
    ),
    additionalContacts: Array.isArray(
      internalData?.metadata?.additionalContacts
    )
      ? internalData?.metadata?.additionalContacts
      : [],
    productCategories: getValidData(internalData?.metadata?.productCategories),
    productProduced: getValidData(internalData?.metadata?.productProduced),
    productSubcategories: getValidData(
      internalData?.metadata?.productSubcategories
    ),
    spend: getValidData(internalData?.metadata?.spend),
    notes: getValidData(internalData?.metadata?.note, []),
    associatedAddress: getValidData(internalData?.metadata?.associatedAddress),
    privateLabel: getValidData(internalData?.metadata?.privateLabel),
    tier: getValidData(internalData?.metadata?.tier),
    status: getValidData(internalData?.metadata?.status),
    confirmedNextAuditDate: getValidData(
      internalData?.metadata?.confirmedNextAuditDate
    ),
    presetAuditDueDate: getValidData(
      internalData?.metadata?.initialPresetDueDate
    ),
    contractor: getValidData(internalData?.metadata?.contractor, false),
    smallFactory: getValidData(internalData?.metadata?.smallFactory, false),
  };

  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [internalData]);

  const dispatch = useDispatch();

  const formatDateToMMDDYY = (dateStr) => {
    if (!dateStr) return "";
    // Create date object and adjust for timezone
    const date = new Date(dateStr + 'T12:00:00'); // Add noon time to avoid timezone issues
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'presetAuditDueDate') {
      // Store the formatted date (MM/DD/YY) in formData
      setFormData({
        ...formData,
        [name]: formatDateToMMDDYY(value),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateFactoryData({
          organisationId: internalData?.organisationId,
          supplierId: internalData?.supplierId,
          factoryId: internalData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, internalData?.supplierId)
  );

  const handleContactChange = (index, field, value) => {
    const newContacts = [...formData.additionalContacts];
    newContacts[index] = {
      ...newContacts[index],
      [field]: value,
    };
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };

  const addContact = () => {
    const newContacts = Array.isArray(formData.additionalContacts)
      ? formData.additionalContacts
      : [];
    setFormData({
      ...formData,
      additionalContacts: [
        ...newContacts,
        {
          contactPersonName: "",
          contactPersonPosition: "",
          contactPersonPhone: "",
          contactPersonEmail: "",
        },
      ],
    });
  };

  const removeContact = (index) => {
    const newContacts = formData.additionalContacts.filter(
      (_, i) => i !== index
    );
    setFormData({
      ...formData,
      additionalContacts: newContacts,
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 1200,
          maxHeight: "90%",
          overflowY: "auto",
          p: 4,
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
          py: 2,
          bgcolor: "background.paper",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Card
                sx={{
                  p: 4,
                  pb: 8,
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                  border: "1px solid rgba(234, 236, 240, 1)",
                  borderRadius: "12px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#344054",
                        display: "inline-block",
                      }}
                    >
                      Edit general site information
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Grid item xs={4}>
                    <Typography>
                      <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#3538CD",
                          display: "flex",
                          textDecoration: "none",
                        }}
                      >
                        {supplierName}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Alias"
                      name="alias"
                      variant="outlined"
                      value={formData.alias}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Site ID"
                      name="factoryId"
                      variant="outlined"
                      value={formData.factoryId}
                      onChange={handleChange}
                      margin="normal"
                      disabled
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={9}>
                    <TextField
                      fullWidth
                      label="Street Address"
                      name="streetAddress"
                      variant="outlined"
                      value={formData.streetAddress}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="City"
                      name="city"
                      variant="outlined"
                      value={formData.city}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="State/Province"
                      name="stateProvince"
                      variant="outlined"
                      value={formData.stateProvince}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={3}>
                    <TextField
                      select
                      fullWidth
                      label="Country"
                      name="country"
                      variant="outlined"
                      value={formData.country}
                      onChange={handleChange}
                      margin="normal"
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    >
                      <option value="">Select a country</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Zip Code"
                      name="zipCode"
                      variant="outlined"
                      value={formData.zipCode}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Contact Person"
                      name="contactPersonName"
                      variant="outlined"
                      value={formData.contactPersonName}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      name="contactPersonPosition"
                      variant="outlined"
                      value={formData.contactPersonPosition}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="contactPersonPhone"
                      variant="outlined"
                      value={formData.contactPersonPhone}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="contactPersonEmail"
                      variant="outlined"
                      value={formData.contactPersonEmail}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 3, mb: 3 }} />
                {formData?.additionalContacts &&
                  formData?.additionalContacts?.map((contact, index) => (
                    <>
                      <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 600 }}
                            >
                              Contact Person {index + 1}
                            </Typography>
                            {/* {index > 0 && (
                          <IconButton
                            onClick={() => removeContact(index)}
                            sx={{ ml: 1 }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )} */}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Contact Person"
                            value={contact.contactPersonName}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonName",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Position"
                            value={contact.contactPersonPosition}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonPosition",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Phone"
                            value={contact.contactPersonPhone}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonPhone",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            label="Email"
                            value={contact.contactPersonEmail}
                            onChange={(e) =>
                              handleContactChange(
                                index,
                                "contactPersonEmail",
                                e.target.value
                              )
                            }
                            margin="normal"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderRadius: "8px",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Divider sx={{ mt: 3, mb: 3 }} />
                    </>
                  ))}
                <Grid item xs={12}>
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={addContact}
                    sx={{
                      color: "#585aeb",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "rgba(88, 90, 235, 0.04)",
                      },
                    }}
                  >
                    Add Another Contact
                  </Button>
                </Grid>
                <Divider sx={{ mt: 3, mb: 3 }} />
                {/* Add Divider and Operation Information section */}
                <Grid item xs={12}>
                  <Divider sx={{ mt: 4, mb: 2 }} />
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#344054",
                      display: "inline-block",
                      mb: 2,
                    }}
                  >
                    Operation Information
                  </Typography>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirmed Next Audit Date"
                      name="confirmedNextAuditDate"
                      type="date"
                      variant="outlined"
                      value={formData.confirmedNextAuditDate || ""}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Preset Audit Due Date"
                          name="presetAuditDueDate"
                          variant="outlined"
                          type="date"
                          value={formData.presetAuditDueDate ? new Date(formData.presetAuditDueDate.split('/').join('-')).toISOString().split('T')[0] : ""}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px",
                              },
                            },
                          }}
                        />
                      </Grid>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} md={5}>
              <Card
                sx={{
                  p: 1,
                  pt: 4,
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                  border: "1px solid rgba(234, 236, 240, 1)",
                  borderRadius: "12px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2, ml: 2 }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#344054",
                        display: "inline-block",
                      }}
                    >
                      Edit confidential business information
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ mt: 0 }}
                  >
                    <Grid item xs={11}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <TextField
                          fullWidth
                          label="Product Categories"
                          name="productCategories"
                          variant="outlined"
                          value={formData.productCategories}
                          onChange={handleChange}
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px", // Set the border-radius here
                              },
                            },
                          }}
                        />
                        <TextField
                          fullWidth
                          label="Products Produced"
                          name="productProduced"
                          variant="outlined"
                          value={formData.productProduced}
                          onChange={handleChange}
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px", // Set the border-radius here
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    {/* <Grid item xs={11}>
                      <TextField
                        fullWidth
                        label="4imprint Note"
                        name="note"
                        variant="outlined"
                        value={formData.note}
                        onChange={handleChange}
                        margin="normal"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "8px", // Set the border-radius here
                            },
                          },
                        }}
                      />
                    </Grid> */}
                  </Grid>

                  <Grid item xs={10}>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#344054",
                        display: "inline-block",
                      }}
                    >
                      Edit additional information
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ mt: 0 }}
                  >
                    <Grid container item xs={11} spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          select
                          fullWidth
                          label="Status"
                          name="status"
                          variant="outlined"
                          value={formData.status || ""}
                          onChange={handleChange}
                          margin="normal"
                          SelectProps={{
                            native: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px", // Set the border-radius here
                              },
                            },
                          }}
                        >
                          <option value="">Select status</option>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Tier"
                          name="tier"
                          variant="outlined"
                          value={formData.tier}
                          onChange={handleChange}
                          margin="normal"
                          disabled
                          SelectProps={{
                            native: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px", // Set the border-radius here
                              },
                            },
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          select
                          fullWidth
                          label="Private Label"
                          name="privateLabel"
                          variant="outlined"
                          value={formData.privateLabel || "No"}
                          onChange={handleChange}
                          margin="normal"
                          SelectProps={{
                            native: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px", // Set the border-radius here
                              },
                            },
                          }}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container item xs={11} spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          select
                          fullWidth
                          label="Contractor"
                          name="contractor"
                          variant="outlined"
                          value={formData.contractor === true ? "Yes" : "No"}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              contractor: e.target.value === "Yes",
                            });
                          }}
                          margin="normal"
                          SelectProps={{
                            native: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px",
                              },
                            },
                          }}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          select
                          fullWidth
                          label="Small Site"
                          name="smallFactory"
                          variant="outlined"
                          value={formData.smallFactory === true ? "Yes" : "No"}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              smallFactory: e.target.value === "Yes",
                            });
                          }}
                          margin="normal"
                          SelectProps={{
                            native: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px",
                              },
                            },
                          }}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid container item xs={11} spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Product Subcategories"
                          name="productSubcategories"
                          variant="outlined"
                          value={formData.productSubcategories}
                          onChange={handleChange}
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px", // Set the border-radius here
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Spend"
                          name="spend"
                          variant="outlined"
                          value={formData.spend || 0}
                          onChange={(e) => {
                            const value = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            setFormData({
                              ...formData,
                              spend: value,
                            });
                          }}
                          margin="normal"
                          InputProps={{
                            startAdornment: <span>$</span>,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderRadius: "8px",
                              },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default FactoryInformationEditModal;
