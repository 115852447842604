import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";

const initialState = {
    users: [],
    loading: false,
    error: null
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUsersByOrganisationId: (state) => {
            state.loading = true;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
            state.loading = false;
            state.error = null;
        },
        setUserError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export const {
    getUsersByOrganisationId,
    setUsers,
    setUserError
} = userSlice.actions;

export default userSlice.reducer;