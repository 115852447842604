import moment from "moment";

export const isBrand = true;

export const getValidData = (data) => {
  return data === "-" || data == null ? "" : data;
};

export const checkInternalEmail = (email) => {
  return email.includes("esger") || email.includes("elm");
};

export const calculateAuditDueDataData = (timestamp) => {
  const currentDate = moment();
  const inputDate = moment(timestamp);
  const nextAuditDueDate = inputDate.clone().add(2, "years");
  const isPastDue = nextAuditDueDate.isBefore(currentDate);
  const daysUntilNextAudit = nextAuditDueDate.diff(currentDate, "days");

  return {
    lastAuditDate: inputDate.format("MMM. D, YYYY"),
    nextAuditDueDate: nextAuditDueDate.format("MMM. D, YYYY"),
    isPastDue,
    daysUntilNextAudit: isPastDue
      ? -Math.abs(daysUntilNextAudit)
      : daysUntilNextAudit,
  };
};

export const convertStatusToNormal = (status) => {
  if (status.toLowerCase() === "ci") {
    return "CI";
  }

  const currBrandName = "4imprint";
  const brandReplaced = status.replace(/brand/i, currBrandName);

  return brandReplaced
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};


