import React, { useEffect } from "react";
import { Grid, Box, Divider, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectOrganisationId, selectUserInfo } from "../../selectors/login.selector";
import { getAnalyticsUrlStart } from "../../reducers/analytics.reducer";
import { selectAnalyticsUrl, selectAnalyticsLoading, selectAnalyticsError } from "../../selectors/analytics.selector";


const InsightDashboard = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const organisationId = useSelector(selectOrganisationId);
  const userInfo = useSelector(selectUserInfo);
  const iframeUrl = useSelector(selectAnalyticsUrl);
  const isLoading = useSelector(selectAnalyticsLoading);
  const error = useSelector(selectAnalyticsError);

  useEffect(() => {
    if (organisationId) {
      dispatch(getAnalyticsUrlStart({ organisationId }));
    }
  }, [organisationId, dispatch]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Grid 
      container 
      sx={{ 
        flexGrow: 1,
        display: "flex", 
        justifyContent: "center", 
        alignItems: "flex-start",
        overflow: "hidden" // Prevents scrolling within the Grid
      }}
    >
      <Grid item xs={12} sx={{ height: "80vh" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",

            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                overflow: "hidden",
                border: "none",
              }}
            >
              <iframe
                src={iframeUrl}
                width="100%"
                height="100%"
                allowTransparency
                style={{
                  border: "none",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: "-100px",
                  outline: "none",
                }}
              ></iframe>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default InsightDashboard;