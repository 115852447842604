import React from "react";
import { Box, Tabs, Tab, Chip, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectAuditPageTab } from "../../selectors/audit.selector";
import { setAuditPageTab } from "../../reducers/audit.reducer";

const AuditTabs = () => {
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setAuditPageTab(newValue));
  };
  const value = useSelector(selectAuditPageTab);
  return (
    <Box sx={{ width: "100%", px: 2, flexGrow: 1, px: 4 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        <Tab
          value="issues"
          label="Findings"
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="cap"
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <span>CAP</span>
              <Tooltip title="This feature is in beta. We welcome your feedback!">
                <Chip
                  label="Beta"
                  size="small"
                  sx={{
                    backgroundColor: "#4C5ED9",
                    color: "white",
                    fontWeight: 500,
                    fontSize: "12px",
                    height: "24px",
                    ml: 1
                  }}
                />
              </Tooltip>
            </Box>
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="document"
          label="Uploaded Documents"
          sx={{ textTransform: "none" }}
        />
        <Tab value="export" label="Export" sx={{ textTransform: "none" }} />
      </Tabs>
    </Box>
  );
};

export default AuditTabs;
