import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Typography, Chip, Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectDueAudits } from "../../selectors/dueAction.selector";
import { useSelector, useDispatch } from "react-redux";
import { selectFactoryList } from "../../selectors/factory.selector";
import { getListOfAllFactories } from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import moment from "moment";
const DueAuditsDataGrid = () => {
  const navigate = useNavigate();

  const selectedDueAudits = useSelector(selectDueAudits);
  
  
  const rows = selectedDueAudits?.map((facility) => ({
    id: facility.factoryId,
    factoryName: facility.factoryName,
    auditDate: facility.auditEndDate || '',
    auditScore: facility.auditScore || '',
    factoryId: facility.factoryId,
    supplierId: facility.supplierId,
    daysUntilDue: facility.daysUntilDue,
    dueStatus: facility.daysUntilDue > 0
      ? `in ${facility.daysUntilDue} Days`
      : `Past Due ${Math.abs(facility.daysUntilDue)} Days`,
    dueDateForNextAudit: facility.initialPresetDueDate || '',
    nextAuditDueDate: facility.nextAuditDueDate || '',
    confirmedNextAuditDate: facility.confirmedNextAuditDate 
      ? moment(facility.confirmedNextAuditDate).format("MMM. D, YYYY") 
      : '',
  }));
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  // Add this filter handling function
  const handleFilterToggle = (filterType) => {
    const newFilters = selectedFilters.includes(filterType)
      ? selectedFilters.filter((f) => f !== filterType)
      : [...selectedFilters, filterType];

    setSelectedFilters(newFilters);
    // Apply filters
    const filtered = rows?.filter((row) => {
      if (newFilters.length === 0) return true;

      if (row?.daysUntilDue) {
        const daysUntilDue = row?.daysUntilDue;

        return newFilters.some((filter) => {
          switch (filter) {
            case "pastDue":
              return daysUntilDue < 0;
            case "due1to20":
              return daysUntilDue >= 1 && daysUntilDue <= 20;
            case "due20to60":
              return daysUntilDue > 20 && daysUntilDue <= 60;
            case "due60plus":
              return daysUntilDue > 60;
            default:
              return true;
          }
        });
      }
    });

    setFilteredRows(filtered);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [selectedDueAudits]);

  const columns = [
    {
      field: "factoryName",
      headerName: "Site",
      width: 150,
      headerAlign: "center",

      renderCell: (params) => (
        <Typography
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px 0px 8px 8px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "auditDate",
      headerName: "Last Audit Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "dueStatus",
      headerName: "Due Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.dueStatus}
          sx={{
            borderRadius: "8px",
            backgroundColor:
              params.row.daysUntilDue < 0 ? "#DC6803" : "#EAECF0",
            color: params.row.daysUntilDue < 0 ? "#FFF" : "#172B4D",
            fontWeight: "500",
          }}
        />
      ),
    },

    {
      field: "nextAuditDueDate",
      headerName: "Due Date for Next Audit",
      type: "string",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "confirmedNextAuditDate",
      headerName: "Confirmed Next Audit Date",
      type: "string",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "auditScore",
      headerName: "Last Audit Result",
      type: "number",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "details",
      headerName: "",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            window.history.pushState(null, "", "/dueAction");

            navigate(
              `/suppliers/${params.row.supplierId}/factory/${params.row.factoryId}`,
              { replace: true }
            );
          }}
          sx={{
            backgroundColor: "#4B68D4",
            color: "white",
            "&:hover": {
              backgroundColor: "#3A54B6",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            ml: 4,

            borderRadius: "8px",
          }}
          size="small"
        >
          To Site
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          Audits Due
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 60+ Days
              </Button>
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGrid
                columns={columns}
                rows={filteredRows || []}
                disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 70}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container

                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",

                            "& .MuiDataGrid-cell": {
                              // Target each cell

                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueAuditsDataGrid;
