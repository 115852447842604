export const containerBox = { px: 2, my: 1, py: 2 };
export const containerGrid = { mb: 2, px: 4 };
export const title = {
  fontWeight: 700,
  fontSize: 15,
  color: "#344054",
  display: "inline-block",
};

export const fieldName = {
  fontWeight: 500,
  fontSize: 15,
  color: "#8B8D97",
};

export const fieldValue = {
  fontWeight: 500,
  fontSize: 15,
  color: "#344054",
};

// audit information styles
export const editModeCard = {
  marginBottom: 24,
  borderRadius: "7px",
  background: "#FFF",
  border: "1px solid var(--Gray-300, #D0D5DD)",
  boxShadow:
    "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
  minHeight: 40,
  maxWidth: "fit-content",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginLeft: 32, // Add margin-left here
};

export const bigHeadingFont30 = {
  fontSize: 30,
  color: "#344054",
  fontWeight: 700,
};

export const headingsNameBold700 = {
  fontWeight: 700,
  fontSize: 15,
  color: "#344054",
};

// auditIssuesTable styles
export const issuesTableContainerPaper = {
  py: 2,
  px: 2,
  m: 1,
  border: "1px solid var(--Gray-300, #D0D5DD)",
  boxShadow:
    "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
  borderRadius: "",
};
export const issuesTablePaper = {
  py: 0,
  px: 3,
  borderRadius: "12px",
  border: "1px solid #EAECF0",
};

export const issuesTableBox = { height: 300, overflow: "auto" };
export const issuesTableCell = {
  fontWeight: 500,
  fontSize: 16,
  borderBottom: "1.5px solid #3538CD",
  color: "#101828",
  py: 1.5,
  px: 1,
  textAlign: "center",
};
export const issuesTableCellWithSolidRightBorder = {
  ...issuesTableCell,
  borderRight: "1.5px solid #3538CD",
};
export const issuesTableCellWithDottedRightBorder = {
  ...issuesTableCell,
  borderRight: "1.5px dotted #3538CD",
};

export const issuesTableHandleFilterBox = {
  display: "flex",
  flexDirection: "column",
};

export const issuesTableFilteredIssuesTableCell = {
  fontWeight: 500,
  fontSize: 16,

  maxWidth: "150px",
  pr: 0,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  cursor: "default",
  borderBottom: "none",
  py: 1.5,
};

export const issuesTableCellForValues = {
  fontWeight: 500,
  fontSize: 16,
  borderBottom: "none",
  py: 1.5,
};
// audit report styles

export const fieldTitle = {
  color: "#475467",
  fontSize: "16px",
  fontWeight: 700,
  mb:1
};

export const auditReportFieldValue = {
  color: "#45464E",
  fontSize: "16px",
  fontWeight: 400,
};

export const auditReportFieldTitleBold500 = {
  ...fieldTitle,
  fontWeight: 500,
};
