import React, { useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { selectNotifications } from "../../selectors/misc.selector";
import {
  getNotifications,
  triggerMarkNotificationAsReadOrUnread,
} from "../../reducers/misc.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import DraftsIcon from "@mui/icons-material/Drafts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  p: 4,
  borderRadius: 2,
  maxHeight: "80vh",
  display: "flex",
  flexDirection: "column",
};

const NotificationModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector(selectNotifications);

  const handleCardClick = (notification) => {
    if (
      notification.supplierId &&
      !notification.factoryId &&
      !notification.auditId
    ) {
      navigate(`/suppliers/${notification.supplierId}`);
    } else if (
      notification.supplierId &&
      notification.factoryId &&
      !notification.auditId
    ) {
      navigate(
        `/suppliers/${notification.supplierId}/factory/${notification.factoryId}`
      );
    } else if (
      notification.supplierId &&
      notification.factoryId &&
      notification.auditId
    ) {
      navigate(
        `/suppliers/${notification.supplierId}/factory/${notification.factoryId}/audit/${notification.auditId}`
      );
    }
  };

  const handleMarkAsReadOrUnread = (e, notificationId, isRead) => {
    e.stopPropagation();
    dispatch(
      triggerMarkNotificationAsReadOrUnread({ notificationId, isRead: !isRead })
    );
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="notification-modal-title"
    >
      <Box sx={style}>
        <Typography
          id="notification-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          Supplier Notes
        </Typography>
        <Box sx={{ overflowY: "auto", flex: 1 }}>
          {notifications?.map((notification) => (
            <Card
              key={notification._id}
              sx={{
                mb: 2,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",

                border: "1px solid rgba(234, 236, 240, 1)",
                borderRadius: "12px",
              }}
              onClick={() => handleCardClick(notification)}
            >
              <CardContent>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{
                      mb: 1,

                      backgroundColor: "#F2F4F7",
                      p: 1,
                      borderRadius: "12px",
                      flex: 1,
                    }}
                  >
                    <strong>Supplier</strong>: {notification.supplierName}
                    {notification.factoryName && (
                      <>
                        {" "}
                        | <strong>Factory</strong>: {notification.factoryName}
                      </>
                    )}
                    {notification.auditName &&
                      notification.auditName !== "null" && (
                        <>
                          {" "}
                          | <strong>Audit</strong>: {notification.auditName}
                          <br />
                        </>
                      )}
                  </Typography>
                  <IconButton
                    onClick={(e) =>
                      handleMarkAsReadOrUnread(
                        e,
                        notification._id,
                        notification?.isRead || false
                      )
                    }
                    sx={{ ml: 1 }}
                  >
                    {notification.isRead ? (
                      <Tooltip title="Mark as unread">
                        <DraftsIcon sx={{ color: "#1976d2" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Mark as read">
                        <MarkEmailUnreadIcon />
                      </Tooltip>
                    )}
                  </IconButton>
                </Stack>
                <Typography
                  sx={{ mt: 1, pl: 1, fontWeight: 600, fontSize: "16px" }}
                >
                  {notification.subject}
                </Typography>
                <Typography
                  sx={{ mt: 1, pl: 1, fontWeight: 400, fontSize: "14px" }}
                >
                  {notification.text}
                </Typography>

                <Typography
                  sx={{ mt: 1, fontSize: "8px", textAlign: "right" }}
                  color="text.secondary"
                >
                  {moment(notification.createdAt).format(
                    "HH:mm A, MMM. DD, YYYY"
                  )}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificationModal;
