import { put, takeLatest, takeEvery, all, call, select } from "redux-saga/effects";
import axios from "axios";
import {
  getQuestionnaires,
  setQuestionnaires,
  setQuestionnaireError,
  getFileSignedUrlRequest,
  getFileSignedUrlSuccess,
  getFileSignedUrlFailure,
  linkResponseToFactory,
  linkResponseToFactorySuccess,
  linkResponseToFactoryFailure,
} from "../reducers/questionnaire.reducer";
import { logout } from "../reducers/login.reducer";
import * as API from "../utils/api";
import {
  selectAuthToken,
  selectOrganisationId,
} from "../selectors/login.selector";

function* getQuestionnairesWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);
    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const requestURL = API.QUESTIONNAIRES.GET_QUESTIONNAIRES.replace(
      "<ORGANISATION_ID>",
      organisationId
    );

    const response = yield axios.get(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setQuestionnaires(response.data.results.questionnaires));
      } else {
        yield put(setQuestionnaireError(response.data.message));
      }
    }
  } catch (error) {
    console.error("Failed to fetch questionnaires:", error);
    yield put(
      setQuestionnaireError(error.message || "Failed to fetch questionnaires")
    );

    if (error?.response?.status === 401) {
      yield put(logout());
    }
  }
}

function* watchQuestionnaireSaga() {
  yield takeLatest(getQuestionnaires.type, getQuestionnairesWorker);
}

function* linkResponseToFactoryWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);
    const { responseId, factoryId } = action.payload;

    const requestURL = API.QUESTIONNAIRES.LINK_RESPONSE_TO_FACTORY.replace(
      "<ORGANISATION_ID>",
      organisationId
    ).replace("<RESPONSE_ID>", responseId);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };

    const body = {
      factoryId: factoryId,
    };

    const response = yield axios.post(requestURL, body, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(linkResponseToFactorySuccess());
      } else {
        yield put(
          linkResponseToFactoryFailure(
            response.data.message || "Failed to link response to factory"
          )
        );
      }
    }
  } catch (error) {
    console.error("Failed to link questionnaire response to factory:", error);
    yield put(
      linkResponseToFactoryFailure(error.message || "An error occurred")
    );

    if (error?.response?.status === 401) {
      yield put(logout());
    }
  }
}

function* fetchFileSignedUrlWorker(action) {
  const fileKey = action.payload;
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  try {
    // Build request URL with the fileKey parameter
    const requestURL = API.QUESTIONNAIRES.GET_FILE_SIGNED_URL.replace(
      "<FILE_PATH>",
      encodeURIComponent(fileKey)
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };

    const response = yield call(axios.get, requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      const { success, results, message } = response.data;

      if (success && results && message) {
        yield put(
          getFileSignedUrlSuccess({
            fileKey,
            signedUrl: results.url,
          })
        );
      } else {
        yield put(
          getFileSignedUrlFailure({
            fileKey,
            error: message.error || "Failed to get signed URL",
          })
        );
      }
    }
  } catch (error) {
    console.error("Error fetching signed URL:", error);

    // Handle 401 unauthorized errors
    if (error.response && error.response.status === 401) {
      yield put(logout());
    }

    yield put(
      getFileSignedUrlFailure({
        fileKey,
        error: error.message || "Failed to fetch signed URL",
      })
    );
  }
}

function* watchFetchFileSignedUrl() {
  yield takeEvery(getFileSignedUrlRequest.type, fetchFileSignedUrlWorker);
}

function* watchLinkResponseToFactorySaga() {
  yield takeLatest(linkResponseToFactory.type, linkResponseToFactoryWorker);
}

export default function* rootSaga() {
  yield all([
    watchQuestionnaireSaga(),
    watchFetchFileSignedUrl(),
    watchLinkResponseToFactorySaga(),
  ]);
}
