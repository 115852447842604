import { createSelector } from "@reduxjs/toolkit";

export const selectQuestionnaires = (state) =>
  state.questionnaire.questionnaires;
export const selectQuestionnaireLoading = (state) =>
  state.questionnaire.loading;
export const selectQuestionnaireError = (state) => state.questionnaire.error;

// Helper selector to get a specific questionnaire by ID
export const selectQuestionnaireById = (id) => (state) => {
  const questionnaires = selectQuestionnaires(state);
  return questionnaires.find(
    (questionnaire) => questionnaire.id === id || questionnaire._id === id
  );
};

// Add this to your existing selectors
export const selectSelectedQuestionnaireId = (state) =>
  state.questionnaire.selectedQuestionnaireId;

export const selectSelectedQuestionnaire = (state) => {
  const selectedId = selectSelectedQuestionnaireId(state);
  const questionnaires = selectQuestionnaires(state);

  if (!selectedId || !questionnaires.length) return null;

  return questionnaires.find(
    (questionnaire) =>
      questionnaire.id === selectedId || questionnaire._id === selectedId
  );
};


export const selectSignedUrls = (state) => state.questionnaire.signedUrls;

export const selectSignedUrlForFile = (state, fileKey) => 
  state.questionnaire.signedUrls[fileKey];

export const selectIsLoadingSignedUrl = (state, fileKey) =>
  state.questionnaire.loadingSignedUrls[fileKey];

export const selectSignedUrlError = (state, fileKey) =>
  state.questionnaire.signedUrlErrors[fileKey];