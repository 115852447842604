import React from "react";
import { Box } from "@mui/material";
import { Typography, Divider, Tooltip, Chip } from "@mui/material";
import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import QuestionnaireTable from "../components/QuestionnaireDetails/questionnaireTable.component";

const QuestionnairePage = () => {
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <Box sx={{ p: { xs: 2, sm: 3 }, maxWidth: "100%" }}>
          <TopBreadcrumbs />
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
              Questionnaires
            </Typography>
            <Tooltip title="The Questionnaires Center is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
              <Chip
                label="Beta"
                size="small"
                sx={{
                  backgroundColor: "#4C5ED9",
                  color: "white",
                  fontWeight: 500,
                  fontSize: "12px",
                  height: "24px",
                }}
              />
            </Tooltip>
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your Questionnaires here
          </Typography>
          <Divider />
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}
          ></Box>
          <QuestionnaireTable />
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionnairePage;
