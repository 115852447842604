import { createSelector } from "@reduxjs/toolkit";

const selectMisc = (state) => state.misc;

export const selectEditModeState = createSelector(
  [selectMisc],
  (misc) => misc.editMode
);

export const selectSaveStatus = createSelector(
  [selectMisc],
  (misc) => misc.saveStatus
);

export const selectEditModeForAuditState = createSelector(
  [selectMisc],
  (misc) => misc.editModeForAudit
);
export const selectFilesUploadedIds = createSelector(
  [selectMisc],
  (misc) => misc.filesUploadedIds
);

export const selectLoadingState = createSelector(
  [selectMisc],
  (misc) => misc.loading
);

export const selectNotifications = createSelector([selectMisc], (misc) =>
  [...(misc?.notifications || [])]?.sort((a, b) => {
    // Sort by isRead: false first, then true
    if (a.isRead === b.isRead) return 0;
    return a.isRead ? 1 : -1;
  })
);

export const selectCountOfUnreadNotifications = createSelector(
  [selectNotifications],
  (notifications) =>
    notifications.filter((notification) => !notification.isRead).length
);
