import React from "react";
import { Box, Tabs, Tab, Tooltip, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierPageTab } from "../../selectors/supplier.selector";
import { setSupplierPageTab } from "../../reducers/supplier.reducer";
import { setFactoryPageTab } from "../../reducers/factory.reducer";
import { selectFacilityPageTab } from "../../selectors/factory.selector";

const FacilityTabs = () => {
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(setFactoryPageTab(newValue));
  };
  const value = useSelector(selectFacilityPageTab);

  const label1 = "Site Status";
  const label2 = "Audit History";
  const label3 = "Environmental";
  const label4 = "Supplementary Due Diligence";

  return (
    <Box sx={{ width: "100%", mt: 1, flexGrow: 1 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        <Tab value="status" label={label1} sx={{ textTransform: "none" }} />
        <Tab value="audits" label={label2} sx={{ textTransform: "none" }} />
        <Tab
          value="environmental"
          label={label3}
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="questionnaires"
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {label4}
              <Tooltip title="The Supplementary Due Diligence Tab is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
                <Chip
                  label="Beta"
                  size="small"
                  sx={{
                    backgroundColor: "#4C5ED9",
                    color: "white",
                    fontWeight: 500,
                    fontSize: "12px",
                    height: "24px",
                    ml: 1,
                  }}
                />
              </Tooltip>
            </Box>
          }
          sx={{ textTransform: "none" }}
        />
      </Tabs>
    </Box>
  );
};

export default FacilityTabs;
