import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ErrorIcon from "@mui/icons-material/Error";
import NoteIcon from "@mui/icons-material/Note";
import CustomChipLabel from "./customChipLabel.component";
import AuditReport from "./auditReport.component";
import AuditReportLeftSideNavigationBar from "./auditReportDetailsLeftSideNavigationBar.component";
import AuditReportTabs from "./auditReportTabs.component";
import AuditReportDetails from "../../pages/auditReportDetails.page";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCapDetailsById,
  setSelectedAuditIssueId,
  updateAuditData,
} from "../../reducers/audit.reducer";
import { selectAuditIssueCAPData, selectAuditData } from "../../selectors/audit.selector";
import DeleteIcon from "@mui/icons-material/Delete";
import { selectEditModeState } from "../../selectors/misc.selector";
import { convertStatusToNormal } from "../../utils/lib";

const AuditsTable = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const auditData = useSelector(selectAuditData);
  const [releaseReport, setReleaseReport] = useState(auditData?.metadata?.reportReleasedByBrand ? "Yes" : "No");
  const [reportReleasedDate, setReportReleasedDate] = useState(auditData?.metadata?.reportReleasedDate || null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Add useEffect to update state when auditData changes
  useEffect(() => {
    if (auditData?.metadata) {
      setReleaseReport(auditData.metadata.reportReleasedByBrand ? "Yes" : "No");
      setReportReleasedDate(auditData.metadata.reportReleasedDate || null);
    }
  }, [auditData]);
  
  const handleClick = (id) => {
    dispatch(setSelectedAuditIssueId(id));
  };
  
  const handleDelete = (capDetailsId) => {
    dispatch(
      deleteCapDetailsById({
        organisationId: params.organisationId,
        supplierId: params.supplierId,
        factoryId: params.factoryId,
        auditId: params.auditId,
        capDetailsId: capDetailsId,
      })
    );
  };
  const editMode = useSelector(selectEditModeState);
  const issuesData = useSelector(selectAuditIssueCAPData);

  const handleReleaseReportChange = (event) => {
    const value = event.target.value;
    setReleaseReport(value);
    
    // Update the publish date based on selection
    if (value === "Yes") {
      const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
      setReportReleasedDate(currentDate);
    } else {
      setReportReleasedDate(null);
    }
  };

  const handleSubmitRelease = () => {
    if (releaseReport) {
      try {
        dispatch(updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes: {
            "reportReleasedByBrand": releaseReport === "Yes",
            "reportReleasedDate": releaseReport === "Yes" ? reportReleasedDate : null
          }
        }));
        setSnackbar({ open: true, message: 'Changes saved successfully', severity: 'success' });
      } catch (error) {
        console.error('Error saving changes:', error);
        setSnackbar({ open: true, message: 'Error saving changes', severity: 'error' });
      }
    }
  };

  return (
    <>
    
      <Box
        elevation={1}
        sx={{
          borderTop: "0px",
          px: 5,
          my: 10
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4 
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Finding Information
          </Typography>
        </Box>

        <Table aria-label="issues table">
          <TableHead>
            <TableRow>
              <TableCell>Finding ID</TableCell>
              <TableCell>Finding</TableCell>
              <TableCell sx={{ width: "12px" }}></TableCell>
              <TableCell sx={{ p: 0 }}>Category</TableCell>
              <TableCell sx={{ p: 0 }}>Severity</TableCell>
              <TableCell>Status</TableCell>

              {/* <TableCell sx={{ width: "8px" }}>Note</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {issuesData.map((row, index) => (
              <TableRow
                key={index}
                sx={
                  index === issuesData.length - 1
                    ? { "&:last-child td, &:last-child th": { border: 0 } }
                    : {}
                }
              >
                <TableCell>{row?.clientCodeTitle}</TableCell>
                <TableCell
                  sx={{
                    wordBreak: "break-word",
                    mr: 0,
                    pr: 1,
                    maxWidth: "470px",
                  }}
                >
                  {row?.issue}
                </TableCell>
                <TableCell
                  sx={{
                    width: "16px",
                    cursor: "pointer",
                    paddingRight: "40px",
                    opacity: 0.5,
                  }}
                  onClick={() => {
                    handleClick(row?.id);
                  }}
                >
                  <IconButton
                    sx={{
                      color: "rgba(0, 0, 0, 0.6)",
                      padding: 0,
                      margin: 0,
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <OpenInFullIcon className="icon" />
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      See detail
                    </Typography>
                  </IconButton>
                </TableCell>
                <TableCell sx={{ p: 0 }}>{row?.type}</TableCell>
                <TableCell sx={{ p: 0 }}>
                  <CustomChipLabel severity={row?.severity} />
                </TableCell>
                <TableCell>
                  {/* TODO: Need to fix the row.statusChipLabel later. It is undefined. Temp change to row.status */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CustomChipLabel
                      cap={row?.status}
                      capLabel={convertStatusToNormal(row?.status || "Open")}
                    />
                    {row?.status?.toLowerCase() == "open" && (
                      <span
                        style={{
                          marginLeft: 8,
                          color: row.dueInDays <= 0 ? "red" : "inherit",
                        }}
                      >
                        {row?.dueInDays <= 0
                          ? `Past Due ${-row?.dueInDays?.toString()} Days`
                          : `Due in ${row?.dueInDays?.toString()} Days`}
                      </span>
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ p: 0 }}>
                  {editMode && (
                    <Button onClick={() => handleDelete(row.id)}>
                      <DeleteIcon sx={{ color: "gray" }} />
                    </Button>
                  )}
                </TableCell>
                {/* <TableCell sx={{ width: "8px" }}>
                  {row.note && (
                    <Tooltip title={test}>
                      <IconButton>
                        <NoteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {issuesData.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
                color: "#8B8D97",
              }}
            >
              No issues found
            </Typography>
          </Box>
        )}
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
          Control Settings
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Report publish date: {reportReleasedDate || ""}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, my: 3, alignItems: 'center', justifyContent: 'flex-start' }}>
          <FormControl sx={{ minWidth: 220 }}>
            <InputLabel id="release-report-label">Publish Report to Supplier</InputLabel>
            <Select
              labelId="release-report-label"
              value={releaseReport}
              label="Release Report to Supplier"
              onChange={handleReleaseReportChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mt: 2, mb: 3 }}>
          <Button 
            variant="contained" 
            onClick={handleSubmitRelease}
            disabled={!releaseReport}
            sx={{
              backgroundColor: "#585aeb",
              textTransform: "none"
            }}
          >
            Save
          </Button>
        </Box>
        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={() => setSnackbar({ ...snackbar, open: false })} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
      

      {/* {selectedIssueId && <AuditReportDetails />} */}
    </>
  );
};

export default AuditsTable;
