import { all, put, select, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    getUsersByOrganisationId,
    setUsers,
    setUserError
} from "../reducers/user.reducer";
import { selectAuthToken, selectOrganisationId } from "../selectors/login.selector";
import * as API from "../utils/api";
import { logout } from "../reducers/login.reducer";

function* getUsersByOrganisationIdWorker() {
    const authToken = yield select(selectAuthToken);
    const organisationId = yield select(selectOrganisationId);

    const requestURL = API.USER.GET_USERS_BY_ORGANISATION_ID.replace(
        "<ORGANISATION_ID>",
        organisationId
    );

    const headers = {
        "Content-Type": "application/json",
        Authorization: authToken,
    };

    try {
        const response = yield axios.get(requestURL, { headers });
        if (response?.status === 200 && response.data?.success) {
            yield put(setUsers(response.data.results.users));
        }
    } catch (error) {
        console.error("Failed to fetch users:", error);
        yield put(setUserError(error.message));
        if (error?.response?.status === 401) {
            yield put(logout());
        }
    }
}

function* watchGetUsersByOrganisationIdSaga() {
    yield takeLatest(getUsersByOrganisationId.type, getUsersByOrganisationIdWorker);
}

export default function* rootSaga() {
    yield all([watchGetUsersByOrganisationIdSaga()]);
}