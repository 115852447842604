import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

const selectDueAction = (state) => state.dueAction;

export const selectDueCaps = createSelector(
  [selectDueAction],
  (dueAction) => dueAction.dueCaps?.caps
);
export const selectDueAudits = createSelector(
  [selectDueAction],
  (dueAction) => dueAction.dueAudits?.audits
);
export const selectDueActionTabs = createSelector(
  [selectDueAction],
  (dueAction) => dueAction.dueActionTabs
);
export const selectDueCIAudits = createSelector(
  [selectDueAction],
  (dueAction) => dueAction.dueCIAudits?.caps
);
