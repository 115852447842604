import { all, put, select, takeLatest } from "redux-saga/effects";

import {
  selectAuthToken,
  selectOrganisationId,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import { toast } from "react-toastify";
import {
  getListOfAllAudits,
  getAuditById,
  setAuditData,
  setListOfAllAudits,
  updateAuditData,
  setAuditIssueCAPData,
  uploadAuditReport,
  setUploadAuditReportFiles,
  processAuditReport,
  setProcessAuditReportFiles,
  regenerateCap,
  setRegenerateCapResult,
  deleteCapDetailsById,
  getListOfAuditsInCapManagement,
  setListOfAuditsInCapManagement,
  beginCAPManagement,
  setAuditStatus,
  updateIssueCapData,
  bulkUpdateCapStatus,
  updateIssueData,
  getResources,
  setResources,
  setCIDueDate,
  setCIDueDateResult,
  triggerResetCAPManagementData,
  resetCAPManagementData,
  triggerEmail,
  uploadCapProof,
  updateIssueProofData,
} from "../reducers/audit.reducer";
import { logout, updateOrganisationResources } from "../reducers/login.reducer";
import { selectSaveStatus } from "../selectors/misc.selector";
import { setFilesUploadedIds, setSaveStatus } from "../reducers/misc.reducer";

function* getListOfAuditsWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { limit, offset, supplierId } = action.payload;
  const requestURL = API.AUDITS.LIST.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", supplierId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = { limit, offset };

  try {
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(
          setListOfAllAudits({
            audits: response.data.results.audits,
            auditCount: response.data.results.auditCount,
            nonActiveAuditCount: response.data.results.nonActiveAuditsByFactory,
          })
        );
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* getAuditByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.GET_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.get(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));

        if (action.payload.origin && action.payload.origin === "notification") {
          if (response.data.results.auditData.state === "active") {
            // navigate to audit details page
            window.location.href = `/suppliers/${action.payload.supplierId}/factory/${action.payload.factoryId}/audit/${action.payload.auditId}`;
          } else {
            alert(
              "This audit is currently in progress for AI analysis. Please come back later when the audit is ready to be reviewed. You will receive an email when the AI audit result is released. Thank you."
            );
          }
        }
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* updateAuditDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.UPDATE_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    ...action.payload.changes,
  };
  const params = {
    issueId: action.payload?.issueId || "",
  };
  try {
    const response = yield axios.put(requestURL, body, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));

        yield put(setSaveStatus("saved"));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* uploadAuditReportWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const formData = new FormData();

  const requestURL = API.AUDITS.UPLOAD_REPORT.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  let headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };

  if (action.payload.uploadToOrganisation) {
    headers["uploadToOrganisation"] = true;
  }
  const toastId = toast.loading("Uploading file...");
  if (action.payload.auditId === "none") {
    let fileUpload = {};

    for (let file of action.payload.file) {
      fileUpload[file.id] = false;

      const formData = new FormData();
      formData.append("file", file.file);

      try {
        const response = yield axios.post(requestURL, formData, {
          headers,
        });

        if (response && response.status === 200 && response.data) {
          if (response.data.success) {
            fileUpload[file.id] = true;
            if (action.payload.uploadToOrganisation) {
              yield put(
                updateOrganisationResources(response.data.results.resources)
              );
              toast.update(toastId, {
                render: "File uploaded successfully",
                type: "success",
                isLoading: false,
                autoClose: 3000,
              });
            }
            // yield put(setAuditData(response.data.results.auditData));
            // yield put(setAuditIssueCAPData(response.data.results.capDetails));
          }
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Upload failed",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        console.error("Failed to fetch suppliers:", error);
        if (error && error.response && error.response.status === 401) {
          // yield put(logout());
        }
        // Handle errors here, possibly dispatching a failure action
      }
    }
    yield put(setFilesUploadedIds(fileUpload));
  } else {
    if (action.payload.mode === "something") {
      let fileUpload = {};

      for (let file of action.payload.file) {
        fileUpload[file.id] = false;

        const formData = new FormData();
        formData.append("file", file.file);
        try {
          const response = yield axios.post(requestURL, formData, {
            headers,
          });

          if (response && response.status === 200 && response.data) {
            if (response.data.files) {
              yield put(setUploadAuditReportFiles(response.data.files));
              // yield put(setAuditData(response.data.results.auditData));
              // yield put(setAuditIssueCAPData(response.data.results.capDetails));
            }
          }
        } catch (error) {
          console.error("Failed to fetch suppliers:", error);
          if (error && error.response && error.response.status === 401) {
            yield put(logout());
          }
          // Handle errors here, possibly dispatching a failure action
        }
      }
    } else {
      formData.append("file", action.payload.file);

      try {
        const response = yield axios.post(requestURL, formData, {
          headers,
        });

        if (response && response.status === 200 && response.data) {
          if (response.data.success) {
            yield put(setUploadAuditReportFiles(response.data.results));
            // yield put(setAuditData(response.data.results.auditData));
            // yield put(setAuditIssueCAPData(response.data.results.capDetails));
          }
        }
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
        if (error && error.response && error.response.status === 401) {
          yield put(logout());
        }
        // Handle errors here, possibly dispatching a failure action
      }
    }
  }
}

function* uploadCapProofWorker(action) {
  const authToken = yield select(selectAuthToken);
  const formData = new FormData();
  const { auditId, issueId } = action.payload;

  const requestURL = API.CAP_MANAGEMENT.UPLOAD_PROOF.replace(
    "<AUDIT_ID>",
    auditId
  ).replace("<ISSUE_ID>", issueId);

  let headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };

  if (action.payload.auditId) {
    let fileUpload = {};

    for (let file of action.payload.file) {
      fileUpload[file.id] = false;

      const formData = new FormData();
      formData.append("file", file.file);
      try {
        const response = yield axios.post(requestURL, formData, {
          headers,
        });

        if (response && response.status === 200 && response.data) {
          if (response.data.success) {
            fileUpload[file.id] = true;
            yield put(
              updateIssueProofData({
                proofDocuments: response.data.results,
                issueId: action.payload.issueId,
                status: response.data.status,
              })
            );
            // yield put(setUploadAuditReportFiles(response.data.results));
            // yield put(setAuditData(response.data.results.auditData));
            // yield put(setAuditIssueCAPData(response.data.results.capDetails));
          }
        }
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
        if (error && error.response && error.response.status === 401) {
          yield put(logout());
        }
        // Handle errors here, possibly dispatching a failure action
      }
    }
    // yield put(setFilesUploadedIds(fileUpload));
  } else {
    formData.append("file", action.payload.file);

    try {
      const response = yield axios.post(requestURL, formData, {
        headers,
      });

      if (response && response.status === 200 && response.data) {
        if (response.data.success) {
          yield put(setUploadAuditReportFiles(response.data.results));
          // yield put(setAuditData(response.data.results.auditData));
          // yield put(setAuditIssueCAPData(response.data.results.capDetails));
        }
      }
    } catch (error) {
      console.error("Failed to fetch suppliers:", error);
      if (error && error.response && error.response.status === 401) {
        yield put(logout());
      }
      // Handle errors here, possibly dispatching a failure action
    }
  }
}

function* processAuditReportWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const formData = new FormData();

  const requestURL = API.AUDITS.PROCESS_REPORT.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  // console.log(`Sending request to URL: ${requestURL}`);
  // console.log(`Endpoint: PROCESS_REPORT`);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };
  const params = {
    emails: "",
  };
  if (action.payload.notificationEmails) {
    params.emails = action.payload.notificationEmails;
  }
  if (action.payload.auditId === "none") {
    let fileUpload = {};

    for (let file of action.payload.file) {
      fileUpload[file.id] = false;

      const formData = new FormData();
      formData.append("file", file.file);
      try {
        const response = yield axios.post(requestURL, formData, {
          headers,
        });

        if (response && response.status === 200 && response.data) {
          if (response.data.success) {
            fileUpload[file.id] = true;
            // yield put(setProcessAuditReportFiles(response.data.results));
          }
        }
      } catch (error) {
        console.error("Failed to process audit report:", error);
        if (error && error.response && error.response.status === 401) {
          yield put(logout());
        }
        // Handle errors here, possibly dispatching a failure action
      }
    }
    yield put(setFilesUploadedIds(fileUpload));
  } else {
    formData.append("file", action.payload.file);

    try {
      const response = yield axios.post(requestURL, formData, {
        headers,
      });

      if (response && response.status === 200 && response.data) {
        if (response.data.success) {
          yield put(setProcessAuditReportFiles(response.data.results));
        }
      }
    } catch (error) {
      console.error("Failed to process audit report:", error);
      if (error && error.response && error.response.status === 401) {
        yield put(logout());
      }
      // Handle errors here, possibly dispatching a failure action
    }
  }
}

function* regenerateCapWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.REGENERATE_CAP.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.put(requestURL, {}, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setRegenerateCapResult(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to regenerate CAP:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}
function* deleteCapDetailsByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.DELETE_CAP_DETAILS_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId)
    .replace("<FINDING_ID>", action.payload.capDetailsId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.delete(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditData(response.data.results.auditData));
        yield put(setAuditIssueCAPData(response.data.results.capDetails));
      }
    }
  } catch (error) {
    console.error("Failed to delete cap details:", error);
  }
}
function* getListOfAuditsInCapManagementWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { factoryId, supplierId } = action.payload;

  const requestURL = API.AUDITS.GET_LIST_OF_AUDITS_IN_CAP_MANAGEMENT.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const params = {
    factoryId: factoryId || null,
    organisationId: organisationId || null,
  };
  try {
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setListOfAuditsInCapManagement(response.data.results.audits));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}
function* beginCAPManagementWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.AUDITS.UPDATE_AUDIT_STATUS.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId)
    .replace("<AUDIT_ID>", action.payload.auditId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    status: 1,
  };
  try {
    const response = yield axios.put(requestURL, body, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setAuditStatus(response.data.results.updatedStatus));
      }
    }
  } catch (error) {
    console.error("Failed to regenerate CAP:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* updateIssueDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const { auditId, issueId, changes } = action.payload;


  const requestURL = API.CAP_MANAGEMENT.UPDATE.replace(
    "<AUDIT_ID>",
    auditId
  ).replace("<ISSUE_ID>", issueId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };
  const body = {
    ...changes,
  };
  const params = {
    issueId: issueId || null,
  };
  try {
    const response = yield axios.put(requestURL, body, { headers, params });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        if (action.payload.changes.submitTriggered) {
          yield put(bulkUpdateCapStatus(response.data.results));
        } else if (action.payload.changes.submitTriggeredForProof) {
          yield put(bulkUpdateCapStatus(response.data.results));
        } else {
          yield put(
            updateIssueCapData({
              issueId,
              actionPlan: response.data.results.actionPlan,
              brandResponse: response.data.results.brandResponse,
              status: response.data.results.status,
              capStatus: response.data.results.capStatus,
              ciDueDate: response.data.results.ciDueDate,
              capClosedDate: response.data.results.capClosedDate,
            })
          );
        }
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* getResourcesWorker(action) {
  const authToken = yield select(selectAuthToken);
  const { organisationId } = action.payload;

  const requestURL = API.AUDITS.GET_RESOURCES;

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = {
    organisationId: organisationId || null,
  };
  try {
    const response = yield axios.get(requestURL, { headers, params });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setResources(response.data.results.resources));
      }
    }
  } catch (error) {
    console.error("Failed to fetch resources:", error);
  }
}

function* setCIDueDateWorker(action) {
  const authToken = yield select(selectAuthToken);
  const { auditId, ciDueDate, issueId } = action.payload;

  const requestURL = API.CAP_MANAGEMENT.SET_CI_DUE_DATE.replace(
    "<AUDIT_ID>",
    auditId
  ).replace("<ISSUE_ID>", issueId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    ciDueDate,
  };
  try {
    const response = yield axios.put(requestURL, body, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setCIDueDateResult(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to set CI due date:", error);
  }
}

function* triggerResetCAPManagementDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const { auditId } = action.payload;

  const requestURL = API.CAP_MANAGEMENT.RESET_CAP_MANAGEMENT_DATA.replace(
    "<AUDIT_ID>",
    auditId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.delete(requestURL, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(resetCAPManagementData(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to reset CAP management data:", error);
  }
}

function* triggerEmailWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const { organisationId, supplierId, factoryId, auditId } = action.payload;

    const requestURL = API.AUDITS.TRIGGER_INTERNAL_EMAIL.replace(
      "<ORGANISATION_ID>",
      organisationId
    )
      .replace("<SUPPLIER_ID>", supplierId)
      .replace("<FACTORY_ID>", factoryId)
      .replace("<AUDIT_ID>", auditId);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };

    const response = yield axios.post(requestURL, {}, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        alert("Email triggered");
      }
    }
  } catch (error) {
    console.error("Failed to trigger email:", error);
  }
}

function* watchAuditSaga() {
  yield takeLatest(getListOfAllAudits.type, getListOfAuditsWorker);
}

function* watchAuditByIdSaga() {
  yield takeLatest(getAuditById.type, getAuditByIdWorker);
}

function* watchUpdateAuditDataSaga() {
  yield takeLatest(updateAuditData.type, updateAuditDataWorker);
}

function* watchUploadAuditReportSaga() {
  yield takeLatest(uploadAuditReport.type, uploadAuditReportWorker);
}

function* watchProcessAuditReportSaga() {
  yield takeLatest(processAuditReport.type, processAuditReportWorker);
}

function* watchRegenerateCapSaga() {
  yield takeLatest(regenerateCap.type, regenerateCapWorker);
}
function* watchDeleteCapDetailsByIdSaga() {
  yield takeLatest(deleteCapDetailsById.type, deleteCapDetailsByIdWorker);
}
function* watchBeginCAPManagementSaga() {
  yield takeLatest(beginCAPManagement.type, beginCAPManagementWorker);
}
function* watchGetListOfAuditsInCapManagementSaga() {
  yield takeLatest(
    getListOfAuditsInCapManagement.type,
    getListOfAuditsInCapManagementWorker
  );
}

function* watchUpdateIssueDataSaga() {
  yield takeLatest(updateIssueData.type, updateIssueDataWorker);
}

function* watchGetResourcesSaga() {
  yield takeLatest(getResources.type, getResourcesWorker);
}

function* watchSetCIDueDateSaga() {
  yield takeLatest(setCIDueDate.type, setCIDueDateWorker);
}

function* watchTriggerResetCAPManagementDataSaga() {
  yield takeLatest(
    triggerResetCAPManagementData.type,
    triggerResetCAPManagementDataWorker
  );
}
function* watchTriggerEmailSaga() {
  yield takeLatest(triggerEmail.type, triggerEmailWorker);
}

function* watchUploadCapProofSaga() {
  yield takeLatest(uploadCapProof.type, uploadCapProofWorker);
}

export default function* rootSaga() {
  yield all([
    watchAuditSaga(),
    watchAuditByIdSaga(),
    watchUpdateAuditDataSaga(),
    watchUploadAuditReportSaga(),
    watchProcessAuditReportSaga(),
    watchRegenerateCapSaga(),
    watchDeleteCapDetailsByIdSaga(),
    watchGetListOfAuditsInCapManagementSaga(),
    watchBeginCAPManagementSaga(),
    watchUpdateIssueDataSaga(),
    watchGetResourcesSaga(),
    watchSetCIDueDateSaga(),
    watchTriggerResetCAPManagementDataSaga(),
    watchTriggerEmailSaga(),
    watchUploadCapProofSaga(),
  ]);
}
