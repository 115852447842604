import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/login.reducer";
import { selectUserInfo } from "../selectors/login.selector";
import { setLeftSideNavigationSelected } from "../reducers/navigation.reducer";
import { selectLeftSideNavigationSelected } from "../selectors/navigation.selector";
import { ComponentStyles } from "../styles";
import LatestUpdateModal from "./latestUpdateModal.component";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { getListOfAuditsInCapManagement } from "../reducers/audit.reducer";
import { selectNumberOfInProgressCAPs } from "../selectors/audit.selector";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationModal from "./AuditDetails/notifications.component";
import { getNotifications } from "../reducers/misc.reducer";
import {
  selectCountOfUnreadNotifications,
  selectNotifications,
} from "../selectors/misc.selector";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { setSidebarExpanded } from "../reducers/navigation.reducer";
import { selectIsSidebarExpanded } from "../selectors/navigation.selector";
import NavItem from './NavItem/navItem.component';

const LeftSideNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userInfo = useSelector(selectUserInfo);
  const selected = useSelector(selectLeftSideNavigationSelected);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = (path) => {
    dispatch(setLeftSideNavigationSelected(path));
    dispatch(
      getListOfAuditsInCapManagement({
        organisationId: userInfo.organisationId,
      })
    );
    navigate(path);
  };
  useEffect(() => {
    if (params["*"] !== "suppliers") {
      dispatch(setLeftSideNavigationSelected(`/${params["*"]}`));
    }
  }, [params]);

  const [UpdateModalOpen, setUpdateModalOpen] = React.useState(false);

  const handleModalOpen = () => {
    setUpdateModalOpen(true);
  };

  const handleModalClose = () => {
    setUpdateModalOpen(false);
  };
  const numberOfInProgressCAPs = useSelector(selectNumberOfInProgressCAPs);
  const [notificationModalOpen, setNotificationModalOpen] =
    React.useState(false);

  const handleNotificationModalOpen = () => {
    setNotificationModalOpen(true);
  };

  const handleNotificationModalClose = () => {
    setNotificationModalOpen(false);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, []);
  const notifications = useSelector(selectNotifications);
  const countOfUnreadNotifications = useSelector(
    selectCountOfUnreadNotifications
  );
  const isExpanded = useSelector(selectIsSidebarExpanded);

  const toggleSidebar = () => {
    dispatch(setSidebarExpanded(!isExpanded));
  };

  const navItems = [
    {
      icon: "/suppliers.svg",
      label: "Suppliers",
      path: "/suppliers"
    },
    {
      icon: "/factories.svg",
      label: "Sites",
      path: "/factories"
    },
    {
      icon: "/insights.svg",
      label: "Insights",
      path: "/insights"
    },
    {
      icon: "/dueAction.svg",
      label: "Action Due",
      path: "/dueAction"
    },
    {
      icon: "/capmanagement.svg",
      label: "CAPs",
      path: "/capmanagement",
      badge: numberOfInProgressCAPs
    },
    {
      icon: "/resources.svg",
      label: "Resources",
      path: "/resources"
    }
  ];

  return (
    <Box
      sx={{
        width: isExpanded ? 210 : 72,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        borderRight: "1px solid #cccccc4a",
        borderBottom: "none",
        borderLeft: "none",
        borderTop: "none",
        backgroundColor: "white",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: isExpanded ? "space-between" : "center",
          px: "10px",
          py: 2,
        }}
      >
        <img
          src="/Elm_Logo.svg"
          alt="Logo"
          style={{
            ...ComponentStyles.logoImage,
            width: '30px',
          }} 
        />
        {isExpanded && notifications?.length > 0 && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="Supplier Notes">
              <IconButton
                onClick={handleNotificationModalOpen}
                sx={{
                  width: 40,
                  height: 40,
                  padding: 0,
                }}
              >
                <Badge
                  badgeContent={countOfUnreadNotifications || 0}
                  color="error"
                >
                  <NotificationsOutlinedIcon
                    style={{ color: "#667085", fontSize: "32px" }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <List sx={{ width: "100%" }}>
        {navItems.map((item) => (
          <NavItem
            key={item.path}
            icon={item.icon}
            label={item.label}
            isSelected={selected === item.path}
            isExpanded={isExpanded}
            onClick={() => handleNavigate(item.path)}
            badge={item.badge}
          />
        ))}
      </List>

      <Box sx={{ flexGrow: 1 }} />
      
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: isExpanded ? 'flex-end' : 'center',
          mb: 2,
          px: 1
        }}
      >
        <Tooltip title={isExpanded ? "Minimize side bar" : "Expand side bar"} placement="right">
          <IconButton
            onClick={toggleSidebar}
            sx={{
              backgroundColor: "white",
              border: "1px solid #cccccc4a",
              '&:hover': {
                backgroundColor: "white",
              },
              width: 32,
              height: 32,
            }}
          >
            {isExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isExpanded ? "space-between" : "center",
          px: "10px",
          py: 2,
        }}
      >
        {isExpanded && (
          <Box>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {userInfo.name}
            </Typography>
          </Box>
        )}
        <Tooltip title={!isExpanded ? "Logout" : ""} placement="right">
          <IconButton
            edge="end"
            aria-label="Logout"
            onClick={() => handleLogout()}
          >
            <img
              src="/logout.svg"
              alt="Logout"
              style={ComponentStyles.navbarListItemIcon}
            />
          </IconButton>
        </Tooltip>
      </Box>

      <NotificationModal
        open={notificationModalOpen}
        onClose={handleNotificationModalClose}
      />
    </Box>
  );
};

export default LeftSideNavbar;
