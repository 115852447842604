import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData, checkInternalEmail } from "../../../utils/lib";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  triggerEmail,
  triggerResetCAPManagementData,
  updateAuditData,
} from "../../../reducers/audit.reducer";
import { AuditStyles } from "../../../styles";
import { selectEmail } from "../../../selectors/login.selector";
import { selectAuditIssueCAPData } from "../../../selectors/audit.selector";
import moment from "moment";

const AuditInformationEditModal = ({
  open = false,
  onClose,
  factoryData,
  auditData,
}) => {
  const initData = React.useMemo(() => ({
    auditTypeFacilityData: getValidData(
      auditData?.metadata?.facilityData?.auditing_standard
    ),
    auditStartDateFacilityData: getValidData(
      auditData?.metadata?.facilityData?.audit_start
    ),

    auditEndDateFacilityData: getValidData(
      auditData?.metadata?.facilityData?.audit_end
    ),

    auditFunder: getValidData(auditData?.metadata?.auditFunder),
    auditFirmFacilityData: getValidData(
      auditData?.metadata?.facilityData?.auditing_firm
    ),

    auditorFacilityData: getValidData(
      auditData?.metadata?.facilityData?.auditor_name
    ),
    primaryLanguageFacilityData: getValidData(

      auditData?.metadata?.facilityData?.language_management
    ),
    workersLanguageFacilityData: getValidData(
      auditData?.metadata?.facilityData?.language_workers
    ),
    state: getValidData(auditData?.state),
    capStartDate: auditData?.metadata?.capStartDate
      ? moment(auditData?.metadata?.capStartDate)
      : null,
    originalAuditScore: getValidData(auditData?.metadata?.originalAuditScore),
    auditScore: getValidData(auditData?.risk?.auditScore),
    capManagedByAuditFirm: getValidData(auditData?.metadata?.capManagedByAuditFirm),
  }), [auditData]);

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const [formData, setFormData] = useState(initData);
  const [initialData, setInitialData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // State for delete confirmation modal
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    if (auditData?._id) {
      setInitialData(initData);
      setFormData(initData);
    }
  }, [auditData?._id]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      try {
        dispatch(
          updateAuditData({
            organisationId: auditData?.organisationId,
            supplierId: auditData?.supplierId,
            factoryId: auditData?.factoryId,
            auditId: auditData?._id,
            changes,
          })
        );
        console.log("updating");
        
        setSnackbar({ open: true, message: 'Changes saved successfully', severity: 'success' });
        onClose();
      } catch (error) {
        console.error('Error saving changes:', error);
        setSnackbar({ open: true, message: 'Error saving changes', severity: 'error' });
      }
    } else {
      setSnackbar({ open: true, message: 'No changes to save', severity: 'info' });
      onClose();
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleDelete = () => {
    // Update the state to 'deleted' and submit the change
    dispatch(
      updateAuditData({
        organisationId: auditData?.organisationId,
        supplierId: auditData?.supplierId,
        factoryId: auditData?.factoryId,
        auditId: auditData?._id,
        changes: { state: "deleted" },
      })
    );
    setOpenDeleteModal(false);
    onClose();
  };

  // Easy copy of all the finding details and legal reference for QA
  const auditDetailsData = useSelector(selectAuditIssueCAPData);

  const aggregatedDetails = auditDetailsData
    .map(
      (item, index) =>
        `${index + 1}.\nIssue Details: ${
          item.issueDetail
        }\n\nAudit Code or Legal reference: ${item.auditBasis}`
    )
    .join("\n\n");

  // ### Added handleCopy Function ###
  const handleCopy = () => {
    navigator.clipboard.writeText(aggregatedDetails).catch((err) => {
      console.error("Failed to copy text: ", err);
    });
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmText, setConfirmText] = useState("");

  const handleResetCAP = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmReset = () => {
    if (confirmText.toLowerCase() === "delete") {
      setOpenConfirmDialog(false);
      setConfirmText("");
      dispatch(triggerResetCAPManagementData({ auditId: auditData?._id }));
    }
  };

  const handleTriggerEmail = () => {
    dispatch(
      triggerEmail({
        organisationId: auditData?.organisationId,
        supplierId: auditData?.supplierId,
        factoryId: auditData?.factoryId,
        auditId: auditData?._id,
      })
    );
  };

  return (
    <Box sx={AuditStyles.containerBox}>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 16,
                color: "#344054",
                display: "inline-block",
              }}
            >
              Edit Audit Information
            </Typography>
          </Grid>
          <Grid item>
            {isInternalUser && (
              <Button
                variant="contained"
                onClick={handleTriggerEmail}
                sx={{
                  backgroundColor: "red",
                  borderRadius: "8px",
                  textTransform: "none",
                  color: "#fff",
                  mr: 1,
                }}
                size="small"
              >
                Trigger Email
              </Button>
            )}
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
              size="small"
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {/* Only Internal User can change this data */}
          {isInternalUser && (
            <>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleCopy}
                  sx={{
                    backgroundColor: "orange",
                    borderRadius: "8px",
                    textTransform: "none",
                    color: "#fff",
                  }}
                >
                  Copy all findings details and legal reference to clipboard
                </Button>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="audit-state-label" sx={{ color: "orange" }}>
                    Audit State
                  </InputLabel>
                  <Select
                    labelId="audit-state-label"
                    name="state"
                    value={formData["state"]}
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px", // Set the border-radius here
                          borderColor: "orange", // Make the border orange
                        },
                      },
                      "& .MuiSelect-select": {
                        color: "orange", // Make the text orange
                      },
                    }}
                  >
                    <MenuItem value="test" sx={{ color: "orange" }}>
                      Internal - Not Approved
                    </MenuItem>
                    <MenuItem value="active" sx={{ color: "green" }}>
                      Internal - Approved
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={"Original Audit Score"}
                  name={"originalAuditScore"}
                  variant="outlined"
                  value={formData["originalAuditScore"]}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                        borderColor: "orange",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "orange", // Make the text orange
                    },
                    "& .MuiInputLabel-root": {
                      color: "orange", // Make the label orange
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label={"Audit Score"}
                  name={"auditScore"}
                  variant="outlined"
                  value={`${formData["auditScore"]} (auto calculated)`}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                        borderColor: "orange",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "orange", // Make the text orange
                    },
                    "& .MuiInputLabel-root": {
                      color: "orange", // Make the label orange
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {Object.keys(initData).map(
            (field, index) =>
              ![
                "state",
                "auditScore",
                "originalAuditScore",
                "capStartDate",
                "auditFunder",
                "auditTypeFacilityData",
                "auditFirmFacilityData",
                "capManagedByAuditFirm",
              ].includes(field) && (
                <Grid item xs={4} key={index}>
                  <TextField
                    fullWidth
                    label={
                      field === "auditType"
                        ? "Auditor Scheme"
                        : field.replace(/([A-Z])/g, " $1").replace("Facility Data", "").trim().charAt(0).toUpperCase() + field.replace(/([A-Z])/g, " $1").replace("Facility Data", "").trim().slice(1)
                    }
                    name={field}
                    variant="outlined"
                    value={formData[field]}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                </Grid>
              )
          )}

          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="audit-funder-label">Audit Funder</InputLabel>
              <Select
                labelId="audit-funder-label"
                name="auditFunder"
                value={formData.auditFunder}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              >
                <MenuItem value="4imprint">4imprint</MenuItem>
                <MenuItem value="Site/Factory">Site/Factory</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="audit-type-label">Auditing Scheme</InputLabel>
              <Select
                labelId="audit-type-label"
                name="auditTypeFacilityData"
                value={formData.auditTypeFacilityData}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Makes the dropdown scrollable
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              >
                <MenuItem value="BRAND">BRAND</MenuItem>
                <MenuItem value="BV PAS">BV PAS</MenuItem>
                <MenuItem value="ERSA">ERSA</MenuItem>
                <MenuItem value="ERSA FLC">ERSA FLC</MenuItem>
                <MenuItem value="ESEP">ESEP</MenuItem>
                <MenuItem value="INTERTEK WCA">INTERTEK WCA</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="QCA">QCA</MenuItem>
                <MenuItem value="QIMA EA">QIMA EA</MenuItem>
                <MenuItem value="SMETA">SMETA</MenuItem>
                <MenuItem value="TUV SUD EQA">TUV SUD EQA</MenuItem>
                <MenuItem value="UL RSWA">UL RSWA</MenuItem>
                <MenuItem value="BSCI">BSCI</MenuItem>
                <MenuItem value="SLCP">SLCP</MenuItem>
                <MenuItem value="BW">BW</MenuItem>
                <MenuItem value="SA8000">SA8000</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="audit-firm-label">Auditor Firm</InputLabel>
              <Select
                labelId="audit-firm-label"
                name="auditFirmFacilityData"
                value={formData.auditFirmFacilityData}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              >
                <MenuItem value="ARCHE ADVISORS">ARCHE ADVISORS</MenuItem>
                <MenuItem value="BSI GROUP">BSI GROUP</MenuItem>
                <MenuItem value="BV">BV</MenuItem>
                <MenuItem value="INSITE">INSITE</MenuItem>
                <MenuItem value="INTERTEK">INTERTEK</MenuItem>
                <MenuItem value="LRQA">LRQA</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="QIMA">QIMA</MenuItem>
                <MenuItem value="RODRIGUEZ COMPLIANCE GROUP">
                  RODRIGUEZ COMPLIANCE GROUP
                </MenuItem>
                <MenuItem value="SGS">SGS</MenuItem>
                <MenuItem value="TUV SUD">TUV SUD</MenuItem>
                <MenuItem value="UL">UL</MenuItem>
                <MenuItem value="EUROFINS">EUROFINS</MenuItem>
                <MenuItem value="V-TRUST">V-TRUST</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            {/* <FormControl fullWidth margin="normal">
              <InputLabel id="cap-by-audit-firm-label">CAP By Audit Firm</InputLabel>
              <Select
                labelId="cap-by-audit-firm-label"
                name="capManagedByAuditFirm"
                value={formData.capManagedByAuditFirm || ''}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl> */}
          </Grid>

          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleResetCAP}
              size="large"
              sx={{ textTransform: "none", width: '100%' }}
            >
              Reset CAP Process
            </Button>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleOpenDeleteModal}
              size="large"
              sx={{ textTransform: "none", width: '100%' }}
            >
              Delete Reports
            </Button>
          </Grid>
        </Grid>
      </form>

      <Modal
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="confirm-reset-modal"
        aria-describedby="confirm-reset-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Confirm Reset
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Are you sure? This will reset all the CAP management data including
            supplier entries and any uploaded documents.
          </Typography>
          <TextField
            fullWidth
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            placeholder="Type 'delete' to confirm"
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              onClick={() => {
                setOpenConfirmDialog(false);
                setConfirmText("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleConfirmReset}
              disabled={confirmText.toLowerCase() !== "delete"}
            >
              Reset Data
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">{"Delete Audit Report"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this audit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AuditInformationEditModal;
